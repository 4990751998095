import React, { Component } from "react";
import './home.scss';
import M from "materialize-css";
import UserService from '../../services/UserService'
import MessageService from '../../services/reports/MessageService'
import ChartComponent from '../shared/chart/chart'
import { injectIntl } from 'react-intl';
import { validAccounts } from '../../constants';
import moment from 'moment'
import store from '../../redux/store'

class Message extends Component {
  constructor(props) {
    super(props);
    this.state = {
      accountSelected: undefined,
      accounts: [],
      status: ['Exitosos', 'Inválidos', 'Errores'],
      messageReportByOperator: {
        labels: [],
        data: [
        ]
      },
      detailReportByWeek: {
        labels: [],
        data: [
          [],
          []
        ]
      },
      user: undefined,
    }
    this.unsubscribe = store.subscribe(() => {
      this.setState({
        accountSelected: store.getState().home.currentAccount
      }, () => this.getReports())
    })
  }
  async getOperatorGraphicData(records) {
    const status = ['successful', 'error'];
    const data = [];
    let operatorChartLabels = []
    for (let j = 0; j < status.length; j++) {
      data[j] = [];
      for (let i = 0; i < records.length; i++) {
        if (!operatorChartLabels.includes(records[i].operator))
          operatorChartLabels.push(records[i].operator);
        data[j].push(records[i][status[j]])
      }
    }
    return {
      labels: operatorChartLabels,
      data: data
    };
  }
  async generateReportForCompany(today = true) {
    let records = [];
    const from = today ? null : moment().subtract(7, 'days').format('YYYY-MM-DD');
    const to = today ? null : moment().subtract(1, 'days').format('YYYY-MM-DD');
    let messageReport = {};
    if (!today) {
      const lastWeekRecords = JSON.parse(localStorage.getItem('lastWeekRecords'))
      if (lastWeekRecords && lastWeekRecords.to === to) {
        messageReport.result = lastWeekRecords.messageReport
        this.setState({
          detailReportByWeek: await this.getDateDetailedData(lastWeekRecords.messageReport)
        })
      } else {
        messageReport = await MessageService.message({ cid: this.state.user.company_id, from: from, to: to })
        localStorage.setItem('lastWeekRecords', JSON.stringify({
          from,
          to,
          messageReport: messageReport.result
        }))
        this.setState({
          detailReportByWeek: await this.getDateDetailedData(messageReport.result)
        })
      }
    } else {
      messageReport = await MessageService.message({ cid: this.state.user.company_id })
    }
    records = messageReport.result.filter(record => {
      return validAccounts.includes(record.user)
    });
    let { accounts } = this.state;
    records.forEach(record => {
      if (!accounts.includes(record.user)) {
        accounts.push(record.user);
      }
    });
    this.state.accounts = accounts
    let dataPie = await this.getPieGraphicData(records)
    return dataPie;
  }

  async getPieGraphicData(records) {
    const data = [];
    let { accountSelected } = this.state;
    if (accountSelected) {
      records = records.filter(record => record.user === accountSelected)
    }
    if (records) {
      let sumSuccessful = 0;
      let sumInvalid = 0;
      let sumError = 0;
      let sumTotal = 0;

      for (let i = 0; i < records.length; i++) {
        sumSuccessful += records[i].successful
        sumInvalid += records[i].invalid
        sumError += records[i].error
        sumTotal += records[i].total
      }

      if (!isNaN((sumSuccessful / sumTotal * 100).toFixed(2)))
        data.push(sumSuccessful / sumTotal * 100).toFixed(2)
      if (!isNaN((sumInvalid / sumTotal * 100).toFixed(2)))
        data.push(sumInvalid / sumTotal * 100).toFixed(2)
      if (!isNaN((sumError / sumTotal * 100).toFixed(2)))
        data.push(sumError / sumTotal * 100).toFixed(2)
    }

    return data;
  }

  async getReports() {
    let { status } = this.state;
    let messageReportByOperator = await MessageService.operator({ account: this.state.accountSelected })
    this.setState({
      messageReportByOperator: await this.getOperatorGraphicData(messageReportByOperator.result),
      messageReportByCompany: { data: await this.generateReportForCompany(), labels: status },
      messageReportByWeek: { data: await this.generateReportForCompany(false), labels: status }
    })
  }
  async componentDidMount() {
    await this.setState({
      user: UserService.getUser() || 'indefinido',
      accounts: this.props.companyAccounts,
      accountSelected: store.getState().home.currentAccount
    })
    await this.getReports();
    M.AutoInit();
    M.FormSelect.init(document.querySelectorAll('select'), {});
  }
  componentWillUnmount() {
    this.unsubscribe()
  }

  async getDateDetailedData(result) {
    const labels = ['Exitosos', 'Inválidos', 'Errores'];
    const status = ['successful', 'invalid', 'error'];
    let detailLastWeekChartLabels = []
    for (let i = 0; i < result.length; i++) {
      if (!detailLastWeekChartLabels.includes(result[i].date)) {
        detailLastWeekChartLabels.push(result[i].date)
      }
    }

    let records = result;

    let data = [];

    if (this.state.accountSelected) {
      records = await records.filter(record => record.user === this.state.accountSelected)
    }

    if (records) {
      for (let j = 0; j < status.length; j++) {
        data[j] = {
          data: [],
          label: labels[j]
        }
        for (let i = 0; i < detailLastWeekChartLabels.length; i++) {
          const dateRecords = records.filter(record => record.date === detailLastWeekChartLabels[i]);
          let sum = 0;

          for (let h = 0; h < dateRecords.length; h++) {
            sum += dateRecords[h][status[j]];
          }

          data[j].data.push(sum)
        }
      }
    }
    let sumary = data.map(x => x.data.reduce((a, b) => a + b, 0))
    return {
      /**
       * check if all values of sumary are zero then send empty array
       */
      data: sumary.every((val, i, arr) => val === 0) ? [] : data,
      labels: detailLastWeekChartLabels
    };
  }

  handleChange = (e) => {
    console.log(e.target.value, 'e')
    this.setState({
      accountSelected: e.target.value.trim()
    }, () => this.getReports())
  }

  render() {
    const { formatMessage } = this.props.intl;
    const {
      user,
      messageReportByOperator,
      messageReportByCompany,
      messageReportByWeek,
      detailReportByWeek
    } = this.state;

    return user && messageReportByCompany ? (
      <div id="home-message-component">
        <div className="row">
          <div className="col s12">
            <ChartComponent
              type={'Pie'}
              chartName={formatMessage({ id: 'charts.name.messageCountByCompany' })}
              labels={messageReportByCompany.labels}
              data={messageReportByCompany.data}
            />
            <ChartComponent
              type={'Doughnutx2'}
              chartName={formatMessage({ id: 'charts.name.messageCountByOperator' })}
              labels={messageReportByOperator.labels}
              data={messageReportByOperator.data}
            />
            <ChartComponent
              type={'Pie'}
              chartName={formatMessage({ id: 'charts.name.messageCountByWeek' })}
              labels={messageReportByWeek.labels}
              data={messageReportByWeek.data}
            />
            <ChartComponent
              type={'Bar'}
              chartName={formatMessage({ id: 'charts.name.detailByWeek' })}
              labels={detailReportByWeek.labels}
              data={detailReportByWeek.data}
            />
          </div>
        </div>
      </div>
    ) : null
  }
}
export default injectIntl(Message);
