
import BaseService from './BaseService';
const api = action => `${process.env.REACT_APP_API}/api/v1/${action}`;
const axios = new BaseService()

const UserService = {
  login: (credentials) => {
    return axios.post(api('signin'), credentials);
  },

  logOut: async () => {
    // return BaseService.post(api('logout'));
    // localStorage.removeItem('user');
    localStorage.clear();
    return localStorage.removeItem('user') ? true : false
  },

  isLogged: () => {
    return localStorage.getItem('user') ? true : false;
  },
  hasRole: (roles) => {
    const userRole = UserService.getUser().role.name;

    if (!roles) {
        return true;
    }

    if (Array.isArray(roles)) {
        return roles.includes(userRole);
    }

    if (roles === userRole) {
        return true;
    }

    return false;
  },
  getUser() {
    let response = JSON.parse(localStorage.getItem('user'))
    return response.user
  },
  getToken() {
    let response = JSON.parse(localStorage.getItem('user'))
    let token = response ? response.token : null
    return token
  }
}
export default UserService;
