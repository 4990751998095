import React, { Component } from "react";
import './footer.scss'
// import store from './../../redux/store';

class Footer extends Component {
  constructor(props) {
    super(props)
    this.state = {
    }
    // store.subscribe(() => {
    //   this.setState({
    //     language: store.getState().language
    //   })
    // })
  }

  render() {
    // let { language } = this.state;
    return(
        <footer className="page-footer">
        <div className="footer-copyright">
          <div className="container">
            {`© ${new Date().getFullYear()} Copyright directo`}
            <a className="grey-text text-lighten-4 right" href={`http://directo.com/en/`} target="_blank" rel="noopener noreferrer" ><b>directo</b></a>
          </div>
        </div>
      </footer>
    )
  }
}
export default Footer;
