import './rateEdit.scss'
import React, { Component } from "react";
import { injectIntl } from 'react-intl';
import M from "materialize-css";
import "materialize-css/dist/css/materialize.min.css";
import Validators from '../../validator/RateValidator'
import { isEmpty } from '../../../../utils/object'

class RatesUpsertModal extends Component {
  constructor(props) {
    super(props)
    this.state = {
      rate: undefined,
      cancelUpsert: false,
      showing: false
    }
  }
  componentWillMount() {
    let { rate, action } = this.props

    this.initializeRate(rate, action);
  }
  componentDidMount() {
    const { callBack } = this.props

    const options = {
      onCloseEnd: () => {
        let { rate } = this.state
        if (this.isRateValid() && !this.cancelUpsert) {
          callBack(rate)
        } else {
          callBack(null)
        }
      },
      inDuration: 250,
      outDuration: 250,
      opacity: 0.5,
      dismissible: false,
      startingTop: "4%",
      endingTop: "10%"
    };
    const modal = M.Modal.init(this.Modal, options);
    modal.open()
  }

  initializeRate(rate, action) {
    if (rate) {
      this.setState({
        rate,
        showing: action === 'show' ? true : false
      })
    } else {
      this.setState({
        rate: {
          type: 'sale',
          provider: '',
          masked: false,
          active: true,
          min_vol: 0,
          max_vol: 0,
          rates: [{
            _id: new Date().getTime(),
            active: true,
            operator: 'Directo',
            price: 0
          }]
        }
      })
    }
  }

  addPrice(e) {
    e.preventDefault()
    let { rate } = this.state
    rate.rates.push({
      active: true,
      operator: '',
      price: 0
    })

    this.setState({
      rate
    })
  }

  changeType(type) {
    let { rate } = this.state

    this.setState({
      rate: {
        ...rate,
        type
      }
    })
  }

  changeActiveStatus() {
    let { rate } = this.state;

    this.setState({
      rate: {
        ...rate,
        active: !rate.active
      }
    })
  }

  changeMaskedStatus() {
    let { rate } = this.state;

    this.setState({
      rate: {
        ...rate,
        masked: !rate.masked
      }
    })
  }

  changeProvider(e) {
    let { rate } = this.state

    this.setState({
      rate: {
        ...rate,
        provider: e.target.value
      }
    })
  }

  changeMinVol(e) {
    let { rate } = this.state

    this.setState({
      rate: {
        ...rate,
        min_vol: e.target.value
      }
    })
  }

  changeMaxVol(e) {
    let { rate } = this.state

    this.setState({
      rate: {
        ...rate,
        max_vol: e.target.value
      }
    })
  }

  changeRatePrice(e, field, operator) {
    e.preventDefault()
    let { rate } = this.state
    let prices = rate.rates

    prices.map(price => {
      if (price.operator === operator) {
        price[field] = e.target.value
      }

      return price
    })

    this.setState({
      rate: {
        ...rate,
        rates: prices
      }
    })
  }

  isRateValid() {
    let { rate } = this.state

    const errors = Validators.validateRate(rate)

    if (isEmpty(errors)) {
      return true
    } else {
      this.errors = errors

      return false
    }
  }

  validRatePrices() {
    let { rate } = this.state

    let valids = rate.rates.filter(Validators.validatePrice)

    return valids.length > 0 ? false : true
  }

  render() {
    const { formatMessage } = this.props.intl
    let { showing, rate } = this.state

    this.errors = Validators.validateRate(rate)

    return (
      <>
        <div
          ref={Modal => {
            this.Modal = Modal;
          }}
          id="modal2"
          className="modal"
        >
          <div className="modal-content">
            <h4>{ rate._id ? formatMessage({id: 'commons.update'}) : formatMessage({id: 'commons.add'}) }</h4>
            <div className="row">
              <div>
                <form className="col s12">
                  <div className="row">
                    <div className="col s4">
                      <label>
                        <input className="with-gap" name="type" type="radio" onChange={e => this.changeType('sale')} checked={rate.type==='sale'} disabled={showing} />
                        <span>{formatMessage({id:'rates.modal.sale'})}</span>
                      </label>
                    </div>
                    <div className="col s4">
                      <label>
                        <input className="with-gap" name="type" type="radio" onChange={e => this.changeType('purchase')} checked={rate.type==='purchase'} disabled={showing} />
                        <span>{formatMessage({id:'rates.modal.purchase'})}</span>
                      </label>
                    </div>
                  </div>
                  <div>
                    <span className={this.errors && this.errors.provider ? 'errors' : ''}>{rate.type === 'sale' ? formatMessage({id:'rates.modal.client'}) : formatMessage({id:'rates.modal.provider'})}</span>
                    <label>
                      <input type="text" defaultValue={rate.provider} disabled={showing} onChange={e => this.changeProvider(e)} />
                    </label>
                  </div>
                  <div className="row">
                    <div className="input-field col s6">
                      <span className={this.errors && this.errors.min_vol ? 'errors' : ''}>{formatMessage({id:'rates.modal.min_vol'})}</span>
                      <label>
                        <input type="text" defaultValue={rate.min_vol} disabled={showing} onChange={e => this.changeMinVol(e)} />
                      </label>
                    </div>
                    <div className="input-field col s6">
                      <span className={this.errors && this.errors.max_vol ? 'errors' : ''}>{formatMessage({id:'rates.modal.max_vol'})}</span>
                      <label>
                        <input type="text" defaultValue={rate.max_vol} disabled={showing} onChange={e => this.changeMaxVol(e)} />
                      </label>
                    </div>
                  </div>
                  <div className="row">
                    <div className="input-field col s6">
                      <label>
                        <input
                          name="masked"
                          type="checkbox"
                          checked={rate.masked}
                          disabled={showing}
                          onChange={e => this.changeMaskedStatus()} />
                          <span>Masked</span>
                      </label>
                    </div>
                  </div>
                  { rate.rates.map((priceRate) => {
                      return (
                        <div key={priceRate._id} className="row">
                          <div className="input-field col s4">
                              <span className={this.errors && this.errors.rates && this.errors.rates[priceRate._id] && this.errors.rates[priceRate._id].operator ? 'errors' : ''}>{formatMessage({id:'rates.modal.operator'})}</span>
                              <label>
                                <input type="text" defaultValue={priceRate.operator} disabled={showing} onChange={e => this.changeRatePrice(e, 'operator', priceRate.operator)} />
                              </label>
                          </div>
                          <div className="input-field col s4">
                              <span className={this.errors && this.errors.rates && this.errors.rates[priceRate._id] && this.errors.rates[priceRate._id].price ? 'errors' : ''}>{formatMessage({id:'rates.modal.price'})}</span>
                              <label>
                                <input type="text" defaultValue={priceRate.price} disabled={showing} onChange={e => this.changeRatePrice(e, 'price', priceRate.operator)} />
                              </label>
                          </div>
                        </div>
                      )
                    })
                  }
                  {
                    showing ? null :
                    <div className="row">
                      <div className="input-field col s6">
                        <a href="#!" className={`waves-effect waves-light btn-small ${this.validRatePrices() ? "" : "disabled"}`}
                          onClick={(e) => this.addPrice(e, 'create')}>
                            <i className="material-icons left">add</i>
                            {formatMessage({id: 'rates.modal.add-price'})}
                        </a>
                      </div>
                    </div>
                  }
                </form>
              </div>
            </div>
          </div>
          <div className="modal-footer">
            <a href="#!"
              className="modal-close waves-effect waves-red btn-flat"
              onClick={() => { this.cancelUpsert = true }}>
              {formatMessage({id: 'modal.cancel'})}
            </a>
            {
              showing ? null :
              <a href="#!"
                className={`modal-close waves-effect waves-green btn-flat btn btn-success ${this.isRateValid() ? '' : 'disabled'}`}>
                { rate._id ? formatMessage({id: 'commons.update'}) : formatMessage({id: 'commons.add'}) }
              </a>
            }
          </div>
        </div>
      </>
    );
  }
}

export default injectIntl(RatesUpsertModal);
