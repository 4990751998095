import React, { Component } from "react";
import './login.scss';
import swal from 'sweetalert';
import UserService from '../../services/UserService'
import { withRouter } from "react-router-dom";
import { injectIntl } from 'react-intl';
import store from '../../redux/store';
class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      credentials: {
        userName: '',
        password: ''
      }
    }
  }

  handleOnChange = (value, prop) => {
    value = value.trim()
    this.setState({
      credentials: {
        ...this.state.credentials,
        [prop]: value
      }
    })
  }

  submit = async (event) => {
    const { formatMessage } = this.props.intl;
    event.preventDefault();
    UserService.login(this.state.credentials).then(async (response) => {
      const user = response.result.user
      localStorage.setItem('user', JSON.stringify(response.result));
      swal({
        title: formatMessage({ id: 'commons.welcome' }) + '!',
        text: user.user_name,
        icon: 'success'
      })
      store.dispatch({
        type: 'USER_LOGGED_IN',
        user
      })

      this.props.history.push('/home')
    }).catch(e => {
      console.log(e.message);
      swal({
        title: formatMessage({ id: 'commons.error' }) + '!',
        text: e.message,
        icon: 'error'
      })
    })
  }

  render() {
    const { userName, password } = this.state.credentials
    return (
      <div id='login-container' >
        <main>
          <center>
            <img
              className="responsive-img"
              src={`/${process.env.REACT_APP_LOGO}`}
              alt=''
            />
            <div className="container">
              <div
                className="z-depth-1 form-container-login lighten-4 row"
                style={{ display: 'inline-block', padding: '32px 48px 0px 48px', border: '1px solid #EEE' }}
              >
                <form className="col s12" >
                  <div className='row'>
                    <div className='col s12'>
                    </div>
                  </div>
                  <div className='row'>
                    <div className='input-field col s12'>
                      <input
                        className=''
                        type='email'
                        name='userName'
                        id='userName'
                        value={userName || ''}
                        onChange={e => this.handleOnChange(e.target.value, 'userName')}
                      />
                      <label htmlFor='userName'>Enter your userName</label>
                    </div>
                  </div>
                  <div className='row'>
                    <div className='input-field col s12'>
                      <input
                        className='validate'
                        type='password'
                        name='password'
                        id='password'
                        value={password || ''}
                        onChange={e => this.handleOnChange(e.target.value, 'password')}
                      />
                      <label htmlFor='password'>Enter your password</label>
                    </div>
                    <label
                      style={{ float: 'right' }}
                    >
                      {/* <a className='white-text' href='#!'><b>Forgot Password?</b></a> */}
                    </label>
                  </div>
                  <br />
                  <center>
                    <div className='row'>
                      <button
                        name='btn_login'
                        className='col s12 btn btn-large waves-effect'
                        onClick={e => this.submit(e)}
                      >
                        Login
                      </button>
                    </div>
                  </center>
                </form>
              </div>
            </div>
          </center>
        </main>
      </div>
    );
  }
}
export default withRouter(injectIntl(Login));
