import React from 'react';
import './image.scss'
import { imageUrl } from '../../../constants'

var Image = (props) => {
    return(
        <div className="image-viewer">
            <img
              width={props.width || '500px'}
              src={props.source ? imageUrl(props.source) : '/email/editor_template/default-image.png'}
              alt={props.alt} />
        </div>
    );
}

export default Image;