import React, { Component } from "react";
import './header.scss'
import $ from 'jquery';
import { withRouter } from "react-router-dom";
import UserService from '../../services/UserService'
import InternationalizationSelect from '../internationalizationSelect/internationalizationSelect';
import FilesSelect from '../files/index'
import SideNav from '../sidenav/sidenav'
import swal from 'sweetalert';
import { injectIntl } from 'react-intl';

class Header extends Component {
  logOut = async (e) => {
    const { formatMessage } = this.props.intl;
    const userResponse = await swal({
      title: formatMessage({ id: 'logout.title' }),
      text: formatMessage({ id: 'logout.text' }),
      icon: "warning",
      buttons: true,
    })
    if (userResponse) {
      UserService.logOut().then(result => {
        localStorage.removeItem('user');
        if ($('.sidenav-overlay'))
          $('.sidenav-overlay').remove()
        this.props.history.push('/login')
      }).catch(e => {
        swal({
          title: "error",
          text: "Algo salio mal",
          icon: "error",
        })
      })
    } else {
      return
    }
  }

  render() {
    return (
      <header className='header-layout'>
        <nav role="navigation" className="nav-extended">
          <div className="nav-wrapper">
            <a data-target="slide-out" href='#vav' className="sidenav-trigger">
              <i className="material-icons">menu</i>
            </a>
            <ul id="nav-mobile" className="right hide-on-med-and-down">
              {
                /**
                <li>
                  <a href="sass.html">Sass</a>
                </li>
                <li>
                  <a href="badges.html">Components</a>
                </li>
                 */
              }
              <li>
                <FilesSelect />
              </li>
              <li>
                <InternationalizationSelect />
              </li>
            </ul>
          </div>
        </nav>
        <SideNav
          logOut={e => this.logOut(e)}
          user={UserService.getUser()}
        />
      </header>
    );
  }
}
export default withRouter(injectIntl(Header));
