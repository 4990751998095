import React, { Component } from 'react'
import store from '../../redux/store';
import './styles.scss'
import M from 'materialize-css'
import { injectIntl } from 'react-intl';
import MessageService from '../../services/reports/MessageService'
import { reportsFolder } from '../../constants'
import $ from 'jquery';

class FilesSelect extends Component {
  constructor(props) {
    super(props)
    this.state = {
      pendingFiles: this.getPendingFiles()
    }
  }
  componentDidMount() {
    const elems = document.querySelectorAll('.dropdown-files');
    store.subscribe(() => {
      let newPendingFiles = this.getPendingFiles()

      this.setState({
        pendingFiles: newPendingFiles
      })
    })
    M.Dropdown.init(elems, {
      inDuration: 300,
      outDuration: 300,
      coverTrigger: true,
      onOpenEnd: () => {
        this.checkFileStatuses()
      }
    });
  }
  getPendingFiles() {
    let pendingFiles = localStorage.getItem('pendingFiles') ? JSON.parse(localStorage.getItem('pendingFiles')) : [];

    let files = pendingFiles.map(file => {
      return {name: file, ready: false}
    })
    return files
  }
  updatePendingFileStatus(fileName) {
    const { pendingFiles } = this.state
    this.setState({
      pendingFiles: pendingFiles.map(file => {
        if (file.name === fileName) {
          return {name:file.name, ready:true}
        } else {
          return file
        }
      })
    })
  }
  checkFileStatuses() {
    const { pendingFiles } = this.state
    pendingFiles.forEach(file => {
      this.checkFile(file.name);
    })
  }
  checkFile(fileName) {
    const fileNameWithExtension = fileName + '.csv.zip';
    const { formatMessage } = this.props.intl;

    MessageService.fileIsReady({fileName:fileNameWithExtension})
    .then((response) => {
      if (response.fileExists) {
        this.updatePendingFileStatus(fileName)
      }
    }).catch(err => {
      M.toast({html:formatMessage({id:'commons.error'})})
    })
  }
  getFile(fileName) {
    $('.container-ring').show();
    const fileNameWithExtension = fileName + '.csv.zip';

    setTimeout(() => {
      window.location.href = reportsFolder + '/' + fileNameWithExtension;
      this.removeFileFromStorage(fileName);
      $('.container-ring').hide();
    }, 5000);
  }
  removeFileFromStorage(fileName) {
    let oldPendingFiles = localStorage.getItem('pendingFiles') ?
      JSON.parse(localStorage.getItem('pendingFiles')) : [];

    let newPendingFiles = oldPendingFiles.filter(file => file !== fileName);

    localStorage.setItem('pendingFiles', JSON.stringify(newPendingFiles))

    if (newPendingFiles.length < 1) {
      store.dispatch({
        type: 'NO_FILE'
      })
    }

    this.setState({
      pendingFiles: this.getPendingFiles()
    })
  }
  render() {
    const { pendingFiles } = this.state
    const { formatMessage } = this.props.intl
    let items = []

    for (let i = 0; i < pendingFiles.length; i++) {
      items.push(
        <li key={pendingFiles[i].name} className={pendingFiles[i].ready ? '' : 'disabled'}>
          <a href="#!" className={pendingFiles[i].ready ? 'ready' : 'disabled'}
            onClick={() => {this.getFile(pendingFiles[i].name)}}>
              {pendingFiles[i].name}
          </a>
        </li>
      )
    }
    return (
      <div id='files-select-container' className="">
        <a className='dropdown-files btn-flatbtn tooltipped'
          href='#!'
          data-position="left"
          data-target='files-list'
          data-tooltip={ items.length ? formatMessage({id:'commons.files'}) : formatMessage({id:'commons.no-files'}) }>
          <i className="material-icons">file_download</i>
        </a>
        <ul id='files-list' className='dropdown-content'>
          {items}
        </ul>
      </div>
    )
  }
}
export default injectIntl(FilesSelect)