import './accounts.scss'
import React, { Component } from "react";
import { injectIntl } from 'react-intl';
import AccountService from '../../../services/AccountService'
import { validAccounts } from '../../../constants'
import Modal from '../../shared/modals/accountEdit'
import swal from 'sweetalert';
import M from "materialize-css";

class Accounts extends Component {
  constructor(props) {
    super(props)
    this.state = {
      rows: [],
      showEditModal: false,
      account: null,
      action: ''
    }
  }

  componentDidMount = async () => {
    await this.getAccounts()
    let elems = document.querySelectorAll('.dropdown-trigger');
    M.Dropdown.init(elems, {inDuration: 300, outDuration: 300});
  }

  populateTable() {
    let { rows } = this.state;
    const { formatMessage } = this.props.intl;
    let tableRows = [];
    for (let index = 0; index < rows.length; index++) {
      const row = rows[index];
      tableRows.push(
        <tr key={row['user']}>
          <td>{row['user'] || ''}</td>
          <td>{row['user'].includes('ENM') ? 'Sí' : 'No'}</td>
          <td>{row['credits'] || 0}</td>
          <td>
            <a className='dropdown-trigger btn' href='#!' data-target={'actions-' + row['user']}>
              {formatMessage({id:'accounts.actions'})}
            </a>
            <ul id={'actions-' + row['user']} className='dropdown-content'>
              <li><a href="#!" onClick={(e) => this.addCredits(e, row)}>{formatMessage({id:'accounts.add'})}</a></li>
              <li><a href="#!" onClick={(e) => this.changeCredits(e, row)}>{formatMessage({id:'accounts.change'})}</a></li>
            </ul>
          </td>
        </tr>
      )
    }
    return (
      <tbody>
        {tableRows}
      </tbody>
    )
  }

  getAccounts = async () => {
    let response = await AccountService.all()

    const result = response.result.users.filter(user => {
      return validAccounts.includes(user.user)
    })

    this.setState({
      rows: result
    })
  }

  addCredits(e, user) {
    e.preventDefault()
    this.setState({
      showEditModal: true,
      account: user,
      action: 'add'
    })
  }

  changeCredits(e, user) {
    e.preventDefault()
    this.setState({
      showEditModal: true,
      account: user,
      action: 'change'
    })
  }

  async closeModal(credits) {
    const { account, action } = this.state
    const { formatMessage } = this.props.intl
    let update = action === 'add' ? 0 : 1;

    this.setState({
      showEditModal: false,
      account: null
    })

    if (credits) {
      this.updateCredits(account.user, credits, update)
      .then(response => {
        let { rows } = this.state
        if (response.success) {
          for (let i = 0; i < rows.length; i++) {
            if (rows[i].user === account.user) {
              rows[i].credits = response.result.credits
            }
          }
          this.setState({
            rows
          })
        } else {
          swal({
            title: formatMessage({ id: 'commons.error' }) + '!',
            text: response.message,
            icon: 'error'
          })
        }
      })
      .catch(error => {
        swal({
          title: formatMessage({ id: 'commons.error' }) + '!',
          text: error.message,
          icon: 'error'
        })
      })
    }
  }

  async updateCredits (user, credits, update) {
    return  await AccountService.updateCredits({
      user, credits, update
    })
  }

  render() {
    const { formatMessage } = this.props.intl;
    const { showEditModal, account, action } = this.state;

    return (
      <div id="directo-report-component">
        <div className="row">
          <div className="input-field col s12">
          </div>
        </div>
        <div className="row">
          <table className="highlight centered responsive-table">
            <thead>
              <tr>
                <th>{formatMessage({ id: 'generals.user' })}</th>
                <th>{formatMessage({ id: 'generals.masked' })}</th>
                <th>{formatMessage({ id: 'generals.credits' })}</th>
                <th>{formatMessage({ id: 'commons.actions' })}</th>
              </tr>
            </thead>
            { this.populateTable() }
          </table>
        </div>
        { showEditModal ?
          <Modal
            account={account.user}
            action={action}
            callBack={credits => {
              this.closeModal(credits)
            }}/> : null }
      </div>
    )
  }
}
export default injectIntl(Accounts);
