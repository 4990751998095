import { CHANGE_TO_LANGUAGE } from '../actionTypes';

const initialState = 'en';

const language = (state = initialState, action) => {
  switch (action.type) {
    case CHANGE_TO_LANGUAGE: {
      return action.language;
    }
    default: {
      return state;
    }
  }
};

export default language;
