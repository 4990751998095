import BaseService from './BaseService';
import { endpoint } from '../constants';
import UserService from './UserService';

const CompanyService = {

  getSmsAccounts: () => {
    const axios = new BaseService({ 'Authorization': UserService.getToken() })
    return axios.get(endpoint(`company/accounts/sms`));
  },
  getEmailAccounts: () => {
    const axios = new BaseService({ 'Authorization': UserService.getToken() })
    return axios.get(endpoint(`company/accounts/email`));
  },
}
export default CompanyService;
