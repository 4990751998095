import React, { useState, useEffect } from 'react'
import store from '../../redux/store'
import EmailReportSerivce from '../../services/reports/EmailService';
import { injectIntl } from 'react-intl';
import InfoBox from './infoBox'
export default injectIntl(({ companyAccounts, intl }) => {
  const { formatMessage } = intl
  const populateCards = metrics => {
    const { information } = data
    return metrics.map((e, i) => {
      return (<InfoBox
        sum={information && information[e] ? information[e] : '0'}
        title={formatMessage({ id: `generals.${e}` })}
        key={i+1}
      />)
    })
  }
  const [data, setData] = useState({
    companyAccounts,
    metrics: ['bounced', 'clicks', 'credits', 'delivered',
      'dropped', 'errors', 'opens', 'other', 'spam', 'invalid']
  })

  const getData = () => {
    const account = store.getState().home.currentAccount
    EmailReportSerivce.getGeneralMetrics({ account }).then(metrics => {
      setData(data => ({
        ...data,
        information: metrics.result[0],
      }))
    })
  }
  useEffect(() => {
    const unsubscribe = store.subscribe(() => { getData() });
    getData()
    console.log(data.information,'information')
    return unsubscribe;
  }, [data['currentAccount']])

  return (
    <div id='home-rcs-component' className='row'>
      {populateCards(data.metrics)}
    </div>)
})
