import { isEmpty } from '../../../utils/object'

const Validators = {
  validateRate: (rate) => {
    let errors = {}

    if (!['sale', 'purchase'].includes(rate.type)) {
      errors.type = 'rate.validation.errors.wrong-option'
    }

    if (typeof rate.provider !== 'string' || rate.provider.trim() === "") {
      errors.provider = 'rate.validation.errors.required-field'
    }

    if (isNaN(rate.min_vol) || rate.min_vol < 1) {
      errors.min_vol = 'rate.validation.errors.min_vol-value'
    }

    if (isNaN(rate.max_vol) || rate.max_vol < 0 || (rate.max_vol > 0 && rate.max_vol < rate.min_vol)) {
      errors.max_vol = 'rate.validation.errors.max_vol-value'
    }

    if (typeof rate.masked !== 'boolean') {
      errors.masked = 'rate.validation.errors.masked'
    }

    if (typeof rate.active !== 'boolean') {
      errors.active = 'rate.validation.errors.active'
    }

    let pricesErrors = rate.rates.map(Validators.validatePrice).filter(el => el !== null)

    if (pricesErrors.length > 0)
      errors.rates = []

    for (let i = 0; i < pricesErrors.length; i++) {
      let current = pricesErrors[i]

      errors.rates[current._id] = {
        operator: current.operator || undefined,
        price: current.price || undefined
      }
    }

    return errors
  },
  validatePrice: (price) => {
    let errors = []

    if (typeof price.operator !== 'string' || price.operator.trim() === "") {
      errors['operator'] = 'rate.validation.errors.operator'
    }

    if (isNaN(price.price) || price.price < 0.01) {
      errors['price'] = 'rate.validation.errors.price'
    }

    if (!isEmpty(errors)) {
      errors['_id'] = price._id

      return errors;
    }

    return null
  }
}

export default Validators