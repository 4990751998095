import { CURRENT_ACCOUNT } from '../actionTypes';
export default (state = { currentAccount: undefined }, action) => {
  console.log(action,'home');
  const { currentAccount } = action

  switch (action.type) {
    case CURRENT_ACCOUNT: {
      return {
        ...state,
        currentAccount
      }
    }
    default: {
      return state;
    }
  }
};
