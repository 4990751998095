import React from 'react'
export default ({ title, sum }) => {
  return (
    <div key={title} className='col s12 m3 l2'>
      <div className='card-panel'>
        <center><span className="count">{sum}</span></center>
        <hr></hr>
        <center>{title}</center>
      </div>
    </div>)
}
