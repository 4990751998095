///api/v1/rate

import BaseService from './BaseService';
import { endpoint } from '../constants';
import UserService from './UserService';

const MessageService = {

  get: (payload) => {
    const axios = new BaseService({ 'Authorization': UserService.getToken() })
    //https://azteca-sms.directo.com:1447/api/v1/
    //message?page=1&limit=10&orderBy=saved_date&sort=asc&from=2020-02-10&to=2020-02-10&search=&account=
    const complement =
      ['page', 'limit', 'orderBy', 'sort', 'from', 'to', 'search', 'account']
        .map(param => payload[param] ? `${param}=${payload[param]}` : `${param}=`).join('&');
    return axios.get(endpoint(`message?${complement}`));
  },
  createBlackList: (payload) => {
    const axios = new BaseService({ 'Authorization': UserService.getToken() })
    return axios.post(endpoint(`message/blacklist`), payload);
  },
  getBlacklists: () => {
    const axios = new BaseService({ 'Authorization': UserService.getToken() })
    return axios.get(endpoint(`message/blacklist`));
  },
  createCampaign: (payload) => {
    const axios = new BaseService({ 'Authorization': UserService.getToken() })
    return axios.post(endpoint(`message/campaign`), payload);
  },
  getCampaign: (payload) => {
    const complement =
      ['date']
        .map(param => payload[param] ? `${param}=${payload[param]}` : `${param}=`).join('&');
    const axios = new BaseService({ 'Authorization': UserService.getToken() })
    return axios.get(endpoint(`message/campaign?${complement}`));
  }
}
export default MessageService;
