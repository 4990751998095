import React from 'react';
import { injectIntl } from 'react-intl';
import M from 'materialize-css'

class ImageUploader extends React.Component {
  modal = undefined
  constructor(props) {
    super(props)
    this.state = {
      url: '',
      previousUrl: undefined,
      validUrl: false
    }
  }
  componentDidMount() {
    const { callback } = this.props
    const options = {
      onOpenStart: () => {
      },
      onOpenEnd: () => {
      },
      onCloseStart: () => {
      },
      onCloseEnd: () => {
        let { url, validUrl } = this.state
        if (validUrl) {
          callback(url)
        } else {
          callback(null)
        }
      },
      inDuration: 250,
      outDuration: 250,
      opacity: 0.5,
      dismissible: false,
      startingTop: "4%",
      endingTop: "10%"
    };
    this.modal = M.Modal.init(this.Modal, options);
    this.modal.open()
  }
  static getDerivedStateFromProps(props, state) {

  }
  closeModal() {
    this.setState({
      validUrl: false
    }, () => {
      this.modal.close()
    })
  }
  render() {
    const { formatMessage } = this.props.intl
    const { validUrl } = this.state
    return(
      <>
        <div
          ref={Modal => {
            this.Modal = Modal;
          }}
          id="modal1"
          className="modal"
        >
          <div className="modal-content">
            <div className="row">
              <div className=" input-field col s12 m6 l4">
                <input id="credits" type="text" className="validate"
                  onKeyUpCapture = {
                    e => {
                        console.log(e.target.value)
                    }
                  } />
              </div>
            </div>
          </div>
          <div className="modal-footer">
            <a href="#!" className="waves-effect waves-red btn-flat" onClick={(() => {
              this.closeModal()
            })}>
              {formatMessage({id: 'modal.cancel'})}
            </a>
            <a href="#!" className={`modal-close waves-effect waves-green btn-flat btn btn-success ${validUrl ? '' : 'disabled'}`}>
              { formatMessage({id: 'modal.set'}) }
            </a>
          </div>
        </div>
      </>
    );
  }
}

export default injectIntl(ImageUploader);