import React from 'react'
import { Route, Redirect } from 'react-router-dom'
import UserService from './services/UserService';

export const ProtectedRoute = ({ component: Component, ...rest }) => {
  const itsFree = [
    '/login',
  ]
  return (
    <Route
      {...rest}
      render={
        (props) => {
          const pathname = props.location.pathname;
          if (itsFree.includes(pathname)) {
            if (UserService.isLogged()) {
              return <Redirect to={{
                pathname: '/home',
                state: {
                  from: props.location
                }
              }}
              />
            } else {
              return <Component {...props} />
            }
          } else {
            if (UserService.isLogged()) {
              return <Component {...props} />
            } else {
              return <Redirect to={{
                pathname: '/login',
                state: {
                  from: props.location
                }
              }}
              />
            }
          }
        }
      }
    />

  )
}
