import BaseService from './BaseService';
import { endpoint } from '../constants';
import UserService from './UserService';

const TemplateEditorService = {

  uploadImage: data => {
    const axios = new BaseService({ 'Authorization': UserService.getToken() })
    return axios.post(endpoint(`email/file/image`), data);
  },
}
export default TemplateEditorService;
