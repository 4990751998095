import $ from 'jquery';
import M from "materialize-css";
import moment from 'moment';
import React, { Component } from "react";
import { injectIntl } from 'react-intl';
import CompanyService from '../../../services/CompanyService';
import MessageService from '../../../services/MessageService';
import Paginator from '../../shared/paginator/container';
import './message-module.scss';

class MessagesModule extends Component {
  constructor(props) {
    super(props);
    this.state = {
      accounts: [],
      date: moment().format('YYYY-MM-DD'),
      page: 1,
      limit: 10,
      search: ''
    }
  }
  async componentDidMount() {
    const accounts = await CompanyService.getSmsAccounts()
    let companyAccounts = accounts.result.map(account => account.user)
    this.setState({
      accounts: companyAccounts
    })
    await this.getInformationRequest()
    M.AutoInit();
    const date = moment().format('YYYY-MM-DD hh:mm:ss');
    let elements = document.querySelectorAll('.datepicker');
    M.Datepicker.init(elements, {
      maxDate: new Date(date),
      autoClose: true
    })
    $('.datepicker').on('change', e => this.handleChange(e, 'date'))
  }

  async getInformationRequest(resetPage = false) {
    let { date, page, limit, account, search } = this.state;

    if (resetPage) {
      page = 1;
    }

    let response = await MessageService.get({
        account: account,
        from: date,
        to: date,
        page: page,
        limit: limit,
        orderBy: 'saved_date',
        sort: 'asc',
        search: search
      });
    // let accounts = []
    // this.setState({accounts})
    // response.data.map(row => row.user)
    // for (let index = 0; index < response.data.length; index++) {
    //   const row = response.data[index];
    //   if (!accounts.includes(row.user))
    //     accounts.push(row.user)
    // }
    this.setState({
      rows: response.data,
      pagination: response.meta,
      // accounts
    })
  }
  populateSelectAccounts = () => {
    let { accounts } = this.state;
    let optionItems = [<option value={''} key='*' defaultValue>Todos</option>]
    for (let index = 0; index < accounts.length; index++) {
      const account = accounts[index];
      optionItems.push(<option value={account} key={account}>{account}</option>)
    }
    return (
      <select
        id='accounts'
        onChange={e => this.handleChange(e,'account')}
      >
        {optionItems}
      </select>
    )
  }

  handleChange = (e, element) => {
    let value = element === 'page' ? e : e.target.value
    switch (element) {
      case 'date':
        let dateString = value.trim();
        let date = moment(new Date(dateString)).format('YYYY-MM-DD');
        // date = moment().diff(date, 'days') > 0 ? date : undefined
        this.setState({
          date
        }, () => this.getInformationRequest(true))
        break;
      case 'account':
        let account = value.trim();
        console.log('account', account)
        this.setState({
          account
        }, () => this.getInformationRequest())
        break;
      case 'search':
        let search = value.trim();
        this.setState({
          search
        }, () => this.getInformationRequest(true))
        break;
      case 'page':
        let page = value;
        this.setState({
          page
        }, () => this.getInformationRequest())
        break;
      default:
        console.log('undefined element', element)
        break;
    }
}
  populateTable = () => {
    let { rows } = this.state;
    return (
      <tbody>
        {
          rows.map(row => {
            return (<tr key={row.camp_aux}>
              <td>{row.saved_date.split(' ')[1]}</td>
              <td>{row.number}</td>
              <td>{row.message}</td>
              <td>{row.user}</td>
              <td>{row.camp_aux}</td>
              <td>{row.uuid}</td>
              <td>{row.sent_date}</td>
              <td>{row.status}</td>
            </tr>)
          })
        }
      </tbody>
    )
  }
  changeSearchCriteria = e => {
    let { search } = this.state;
    const value = e.target.value;
    if (search !== value.trim() && (value.trim().length > 2 || value.trim().length === 0))
      this.handleChange(e, 'search')
  }
  render() {
    let { pagination } = this.state;
    const { formatMessage } = this.props.intl;
    return pagination ?
      (
        <div id="message-module-component">
          <div className="row">
            <div className=" input-field col s12 m6 l4">
              <input id="first_name" type="text" className="validate"
                onKeyUpCapture= {
                  e => {
                      this.changeSearchCriteria(e)
                  }
                } />
              <label htmlFor="first_name">Criterio de búsqueda</label>
            </div>
            <div className="input-field col s12 m6 l4">
              <label>
                {formatMessage({id: 'generals.date' })}
            </label>
              <input
                type="text"
                className="datepicker"
                id="datepicker"
              >
              </input>
            </div>
            <div className=" input-field col s12 m6 l4">
              {this.populateSelectAccounts()}
            </div>
          </div>
          <div className='row'>
            <table className="highlight centered responsive-table">
              <thead>
                <tr>
                  <th>{formatMessage({ id: 'generals.time' })}</th>
                  <th>{formatMessage({ id: 'generals.cellphoneNumber' })}</th>
                  <th>{formatMessage({ id: 'generals.message' })}</th>
                  <th>{formatMessage({ id: 'generals.account' })}</th>
                  <th>{formatMessage({ id: 'generals.auxiliaryField' })}</th>
                  <th>UUID</th>
                  <th>{formatMessage({ id: 'generals.deliveryTime' })}</th>
                  <th>{formatMessage({ id: 'generals.status' })}</th>
                </tr>
              </thead>
              {this.populateTable()}
            </table>
          </div>
          <Paginator
            totalPages={pagination.totalPages}
            page={pagination.page}
            hasPrevPage={pagination.hasPrevPage}
            hasNextPage={pagination.hasNextPage}
            maxPages={10}
            callBack={page => {
              this.handleChange(page, 'page')
            }}
          />
        </div>
      ) : null
  }
}
export default injectIntl(MessagesModule);
