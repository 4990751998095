///api/v1/rate

import BaseService from './BaseService';
import { endpoint } from '../constants';
import UserService from './UserService';

const RateService = {

  getRates: () => {
    const axios = new BaseService({ 'Authorization': UserService.getToken() })
    return axios.get(endpoint(`rate`));
  },
}
export default RateService;
