import React, { Component } from "react";
import './home.scss';
import M from "materialize-css";
import moment from 'moment';
import { injectIntl } from 'react-intl';
import CompanyService from '../../services/CompanyService'
import Message from './messages';
import Email from './email';
// import Rcs from './rcs'
import store from '../../redux/store'
class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {
      accounts: [],
      currentService: 'messages'
    }
  }

  async componentDidMount() {
    await this.populateAccounts()
    setInterval(() => this.setState({
      time: moment().format('DD/MM/YY - HH:mm:ss') }),1000);
  }
  async populateAccounts() {
    let { currentService } = this.state
    let accountsCompanyService;
    switch (currentService) {
      case 'messages':
        accountsCompanyService = CompanyService.getSmsAccounts;
        break;
      case 'rcs':
        accountsCompanyService = CompanyService.getSmsAccounts;
        break;
      case 'email':
        accountsCompanyService = CompanyService.getEmailAccounts;
        break;
      default:
        throw new Error('undefined endpoint')
    }
    const accounts = await accountsCompanyService()
    let companyAccounts = accounts.result.map(account => account.user)
    this.setState({
      accounts: companyAccounts
    }, () => {
      M.AutoInit();
      M.FormSelect.init(document.querySelectorAll('select'), {});
    })
  }
  productSelector() {
    const { currentService } = this.state
    return (
      <select onChange={e => {
        this.setState({
          currentService: e.target.value.trim()
        }, async () => await this.populateAccounts())
      }} >
        {
          [
            {label:'sms', value:'messages'},
            // {label:'rcs', value:'rcs'},
            { label: 'mailing', value: 'email' }
          ].map((item, i) => <option key={i+1} value={item.value} defaultValue={item.value === currentService} >{item.label.toUpperCase()}</option>)
        }
      </select>)
  }

  populateSelectAccounts = () => {
    let { accounts } = this.state;
    let optionItems = [<option value='' key='*' defaultValue>Todos</option>]
    accounts.map((account) => optionItems.push(<option value={account} key={account}>{account}</option>));
    return (
      <select
        id='accounts'
        onChange={e => {
          store.dispatch({
            type: 'CURRENT_ACCOUNT',
            currentAccount: e.target.value.trim()
          })
        }}
      >
        {optionItems}
      </select>
    )
  }
  displayService(service) {
    let { accounts } = this.state;
    switch (service) {
      case 'messages':
        return <Message companyAccounts={accounts} />
      case 'rcs':
        // return <Rcs companyAccounts={accounts} />
        break;
      case 'email':
        return <Email companyAccounts={accounts} />
      default:
        return (<a href="#!"> undefined services </a>)
    }
  }
  render() {
    const { formatMessage } = this.props.intl;
    let { currentService } = this.state;
    return (
      <div id="home">
        <div className="row">
          <div className="input-field col s6 select-container">
            {this.productSelector()}
          </div>
          <div className="input-field col s6 select-container">
            {this.populateSelectAccounts()}
            <label>{formatMessage({ id: 'generals.accounts' })}</label>
          </div>
        </div>
        {this.displayService(currentService)}
      </div>
    )
  }
}
export default injectIntl(Home);
