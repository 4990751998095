import React, { useEffect, useState } from 'react';
import { injectIntl } from 'react-intl';
import EmailReportService from '../../../services/reports/EmailService';
import InfoBox from './infoBox';

export default injectIntl(( {intl, account, from, to} ) => {
  const {formatMessage} = intl
  const [data, setData] = useState([])
  const [metrics, setMetrics] = useState([])
  useEffect(() => {
    getData()
  }, [account, from, to])
  const getData = async () => {
    EmailReportService.getGeneralMetricsByDate({ account, from, to, sentType: '0' }).then(response => {
      let data = response.result.length ? response.result[0] : {}
      let metrics = Object.keys(data).map(key => {
        return data[key] > 0 ? key : null
      }).filter(key => { return key })
      setMetrics(metrics)
      setData(data)
    })
  }
  const populateCards = () => {
    return [...metrics.map((e, i) => {
      return (<InfoBox
        sum={data && data[e] ? data[e] : '0'}
        title={formatMessage({ id: `generals.${e}` })}
        key={i+1}
      />)
    }), <InfoBox
          sum={data && data['opens'] && data['delivered'] ?
          `${(data['opens'] / data['delivered'] * 100).toFixed(2)} %` : '0%'}
          title={`${formatMessage({ id: 'generals.opens' })}/${formatMessage({ id: 'generals.delivery' })}`}
          key='avg'
        />]
  }
  return (
    <>
      <div id='card-component' className='row'>
        {populateCards()}
      </div>
    </>
  )
})
