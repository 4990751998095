import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { IntlProvider, addLocaleData } from 'react-intl';
import $ from 'jquery';
import './index.scss';
import App from './App';
import store from './redux/store';
import * as serviceWorker from './serviceWorker';
import locale_en from 'react-intl/locale-data/en';
import locale_de from 'react-intl/locale-data/es';
import messages_es from "./translations/es.json";
import messages_en from "./translations/en.json";
import country_codes from "./translations/country_codes.js";
addLocaleData([...locale_en, ...locale_de]);
const messages = {
  'es': messages_es,
  'en': messages_en
};
class IntlWrapper extends Component {
  constructor(props) {
    super(props)
    this.state = {
      ipGeolocation: null
    }
    store.subscribe(() => {
      this.setState({
        language: store.getState().language
      })
    })
  }
  async componentDidMount() {
    let ipGeolocation;
    try {
      ipGeolocation = await $.getJSON("http://ip-api.com/json");
      //ipGeolocation =  ipGeolocation.countryCode ? ipGeolocation : { countryCode:'MX' }
    } catch (error) {
      console.log(error);
      ipGeolocation = { countryCode: 'MX' }
    }
    this.setState({
      ipGeolocation: ipGeolocation,
      countryCode: ipGeolocation.countryCode
    }, () => {
      let language = 'es'
      if (!localStorage.getItem('language')) {
        const { countryCode } = this.state;
        language = Object.keys(country_codes)
          .find(lang => country_codes[lang].includes(countryCode))
        //console.log(language, countryCode);
        localStorage.setItem('language', language);
      } else {
        language = localStorage.getItem('language')
      }
      this.setState({
        language
      })
    })
  }
  render() {
    const { language } = this.state;
    const { children } = this.props
    if (language == null) return false;
    return (
      <IntlProvider
        locale={language}
        messages={messages[language]}
      >
        {children}
      </IntlProvider>
    )
  }
}

ReactDOM.render(
  <IntlWrapper>
    <App />
  </IntlWrapper>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
