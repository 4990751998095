// import UserService from '../../services/UserService';
// import { withRouter } from "react-router-dom";
import React from 'react';
import './sidenav.scss'
import { injectIntl } from 'react-intl';
import * as routes from '../../routes'

const SideNavComponent = (props) => {
  const { formatMessage } = props.intl;
  const { user } = props;
  const userRole = user.role.name
  const getJsxRoutes = () => {
    const fn = routes => {

      return routes.map(route => {
        return (
          route && route.roles.includes(userRole) ?
            route.children ?
              [
                <li key={route.id}>
                  <a
                    href="#!"
                    className={route.children ? 'collapsible-header waves-effect waves-teal' : 'waves-effect waves-teal'}
                  >
                    <i className="material-icons">{route.icon}</i>
                    {formatMessage({ id: route.title })}
                  </a>
                  <div className='collapsible-body'>
                    <ul>
                      {fn(route.children)}
                    </ul>
                  </div>
                </li>
              ] :
              <li key={route.id}>
                <a
                  href={route.url}>
                  <i className="material-icons">{route.icon}</i>
                  {formatMessage({ id: route.title })}
                </a>
              </li>
            : null)
      })
    }
    return (<ul className='collapsible collapsible-accordion' >
      {fn(routes)}
    </ul>)
  }
  return (
    <ul id="slide-out" className="sidenav">
      <li>
        <a
          className=' text-view logout'
          href='#logout'
          onClick={e => props.logOut(e)}>
          <span className='material-icons'>exit_to_app</span>
        </a>
        <div className="user-view">
          <div className="background">
            <img src="/wall_default2.jpg" alt="" />
          </div>
          <a href="#!">
            <img className="circle" src="/avatar.webp" alt="" />
          </a>
          <a href="#name">
            <span className="text-view name"><b>{user.user_name}</b></span>
          </a>
          <a href="#email">
            <span className="text-view email">{user.company}</span>
          </a>
        </div>
      </li>
      {getJsxRoutes()}
    </ul>
  );
}

export default injectIntl(SideNavComponent);
