import $ from 'jquery';
import M from "materialize-css";
import moment from 'moment';
import React, { Component } from "react";
import { injectIntl } from 'react-intl';
import { maskedAccounts } from '../../../../constants';
import AccountService from '../../../../services/AccountService';
import RateService from '../../../../services/RateService';
import MessageService from '../../../../services/reports/MessageService';
import UserService from '../../../../services/UserService';
import './directo-report.scss';

class DirectoReport extends Component {
  constructor(props) {
    super(props);
    this.state = {
      accounts: [],
      accountSelected: undefined,
      date: undefined,
      rows: []
    }
  }
  async componentDidMount() {
    M.AutoInit();
    let elements = document.querySelectorAll('.datepicker');
    await this.setState({ user: UserService.getUser() || 'indefinido' })
    const date = moment().format('YYYY-MM-DD hh:mm:ss');
    $('.datepicker').on('change', e => this.handleChange(e))
    M.Datepicker.init(elements, {
      maxDate: new Date(date),
      autoClose: true
    })
    let rateResponse = await RateService.getRates();
    let rates = rateResponse.result.rates.filter(e => e.active);
    this.setState({
      rates
    },
      () => {
        this.getReports();
        this.getAccounts();
      })
  }

  getAccounts = async () => {
    let accounts = await AccountService.getAccounts()

    this.setState({
      accounts: accounts.result
    })
  }

  populateSelectAccounts = () => {
    let { accounts } = this.state;
    let optionItems = [<option value={''} key='*' defaultValue>Todos</option>]
    for (let index = 0; index < accounts.length; index++) {
      const account = accounts[index];
      optionItems.push(<option value={account.user} key={account.user}>{account.user}</option>)
    }
    return (
      <select
        id='accounts'
        onChange={this.handleChange}
      >
        {optionItems}
      </select>
    )
  }

  handleChange = (e) => {
    switch (e.target.id) {
      case 'accounts':
        this.setState({
          accountSelected: e.target.value.trim()
        }, () => this.getReports())
        break;
      case 'datepicker':
        let dateString = e.target.value.trim();
        let date = moment(new Date(dateString)).format('YYYY-MM-DD');
        date = moment().diff(date, 'days') > 0 ? date : undefined
        this.setState({
          date
        }, () => this.getReports())
        break;
      default:
        break;
    }
  };

  getReports = async () => {
    let { user, date, accountSelected } = this.state;
    let payload = { cid: user.company_id, account: accountSelected }
    if (date)
      payload = {
        ...payload,
        from: date,
        to: date
      }
    let response = await MessageService.message(payload)
    let { result } = response;

    this.setState({
      rows: await this.completeInformation(result)
    }, () => M.FormSelect.init(document.querySelectorAll('select'), {}))
  };
  completeInformation = async result => {
    const { rates } = this.state;
    let saleRates = []
    let buyRates = []
    for (let index = 0; index < rates.length; index++) {
      const tempRate = rates[index];
      if (tempRate.type === 'sale') {
        saleRates.push(tempRate)
      } else {
        buyRates.push(tempRate)
      }
    }
    let completedInformation = []

    for (let i = 0; i < result.length; i++) {
      let record = result[i] 
      if (record.credits <= 0 || record.operator.toLowerCase() === 'sin operador') {
        record.buyTotal = 0;
        record.saleTotal = 0;
        completedInformation.push(record);
      } else {
        //saleTotal
        let factor = 1;
        const company = this.getAccountCompany(record.user)
        const currentSaleRate = saleRates.find(el => {
          if (maskedAccounts.includes(record.user)) {
            return el.provider === company &&
              el.min_vol <= record.credits &&
              (el.max_vol === 0 || el.max_vol >= record.credits) &&
              el.masked === true;
          } else {
            return el.provider === company &&
              el.min_vol <= record.credits &&
              (el.max_vol === 0 || el.max_vol >= record.credits);
          }
        });
        factor = currentSaleRate ? currentSaleRate.rates[0].price : 0;
        record.saleTotal = record.credits * factor;
        //buyTotal
  
        if (!record.provider) {
          record.buyTotal = 0;
          completedInformation.push(record);
        } else {
          let currentBuyRate = buyRates.find(el => {
            console.log('el',el)
            console.log('record',record)
            return el.provider.toLowerCase() === record.provider.toLowerCase() &&
              el.min_vol <= record.credits &&
              (el.max_vol === 0 || el.max_vol >= record.credits);
          });
    
          const matchedRate = currentBuyRate.rates.find(
            (rate) =>
              rate.active &&
              rate.operator.toLowerCase() === record.operator.toLowerCase()
          );
          const operatorRate = matchedRate
            ? matchedRate
            : currentBuyRate.rates.find(
                (rate) => rate.operator === "MVNO"
              );
          // console.log(`Tarifa de compra para ${record.provider}`, operatorRate);
          factor = operatorRate.price;
          record.buyTotal = record.credits * factor;
          completedInformation.push(record);
        }
      }
    }
    return completedInformation;
  };
  getAccountCompany(account) {
    let accountData = this.state.accounts.find(el => el.user === account)

    return accountData.company;
  }
  getTotals(field) {
    let sum = 0;
    const { rows } = this.state;

    rows.forEach(record => {
      sum += record[field];
    });

    return sum;
  }
  populateTable = () => {
    let { rows } = this.state;
    let tableRows = [];
    for (let index = 0; index < rows.length; index++) {
      const row = rows[index];
      tableRows.push(
        <tr key={row['date'] + '/' + row['user'] + '/' + row['provider'] + '/' + row['operator']}>
          <td>{row['date'] || ''}</td>
          <td>{row['user'] || ''}</td>
          <td>{row['provider'] || ''}</td>
          <td>{row['operator'] || ''}</td>
          <td>{row['successful'] || 0}</td>
          <td>{row['timeRestricted'] || 0}</td>
          <td>{row['error'] || 0}</td>
          <td>{row['invalid'] || 0}</td>
          <td>{row['total'] || 0}</td>
          <td>{row['credits'] || 0}</td>
          <td>{row['saleTotal'] ? row['saleTotal'].toFixed(2) : 0}</td>
          <td>{row['buyTotal'] ? row['buyTotal'].toFixed(2) : 0}</td>
          <td>{(row['saleTotal'] - row['buyTotal']).toFixed(2) || 0}</td>
        </tr>
      )
    }
    tableRows.push(
      <tr key={'total'}>
          <td colSpan="3"></td>
          <td textalign="right"><b>Total</b></td>
          <td><b>{this.getTotals('successful')}</b></td>
          <td><b>{this.getTotals('timeRestricted')}</b></td>
          <td><b>{this.getTotals('error')}</b></td>
          <td><b>{this.getTotals('invalid')}</b></td>
          <td><b>{this.getTotals('total')}</b></td>
          <td><b>{this.getTotals('credits')}</b></td>
          <td><b>{this.getTotals('saleTotal').toFixed(2)}</b></td>
          <td><b>{this.getTotals('buyTotal').toFixed(2)}</b></td>
          <td><b>{(this.getTotals('saleTotal') - this.getTotals('buyTotal')).toFixed(2)}</b></td>
        </tr>
    )
    return (
      <tbody
      >
        {tableRows}
      </tbody>
    )
  }
  render() {
    const { formatMessage } = this.props.intl;
    return (
      <div id="directo-report-component">
        <div className="row">
          <div className="input-field col s12 m6 l4">
            <label>
              {formatMessage({id: 'generals.date' })}
            </label>
            <input
              type="text"
              className="datepicker"
              id="datepicker"
            >
            </input>
          </div>
          <div className=" input-field col s12 m6 l4">
            {this.populateSelectAccounts()}
          </div>
          <div className=" input-field col s12 m6 l4">
            <button href=""
              onClick={this.getReports}
              className="btn-floating btn-large waves-effect waves-light">
              <i className="material-icons">
                refresh
              </i>
            </button>
          </div>
        </div>
        <div className="row">
          <table className="highlight centered responsive-table">
            <thead>
              <tr>
                <th>{formatMessage({ id: 'generals.date' })}</th>
                <th>{formatMessage({ id: 'generals.user' })}</th>
                <th>{formatMessage({ id: 'generals.provider' })}</th>
                <th>{formatMessage({ id: 'generals.operator' })}</th>
                <th>{formatMessage({ id: 'generals.successful' })}</th>
                <th>{formatMessage({ id: 'generals.restricted' })}</th>
                <th>{formatMessage({ id: 'generals.errors' })}</th>
                <th>{formatMessage({ id: 'generals.invalid' })}</th>
                <th>{formatMessage({ id:'generals.total'})}</th>
                <th>{formatMessage({ id: 'generals.credits' })}</th>
                <th>{formatMessage({ id: 'generals.sale' })}</th>
                <th>{formatMessage({ id: 'generals.cost' })}</th>
                <th>{formatMessage({ id: 'generals.profitMargin' })}</th>
              </tr>
            </thead>
            {this.populateTable()}
          </table>
        </div>
      </div>
    )
  }
}
export default injectIntl(DirectoReport);
