import React, { useEffect, useState } from 'react';
import { injectIntl } from 'react-intl';
import EmailReportService from '../../../services/reports/EmailService';
import Paginator from '../../shared/paginator/container';
import { storeFileName } from '../../shared/utils/localStore';

export default injectIntl(( {intl, account, search, from, to, sentType} ) => {
  const {formatMessage} = intl
  const [data, setData] = useState([])
  const [pagination, setPagination] = useState({
    page: 1,
    limit: 10,
  })
  useEffect(() => {
    getData()
  }, [pagination['page']])
  useEffect(() => {
    if (pagination['page'] !== 1) {
      setPagination(() => ({
        ...pagination,
        page: 1
      }))
    } else {
      getData()
    }
  }, [account, search, from, to, sentType])

  const getData = async () => {
    const response = await EmailReportService.getEmailDashboardReport({
      account,
      search,
      from,
      to,
      sentType,
      page: pagination['page'],
      limit: pagination['limit']
    })
    setData(response.result)
    setPagination(() => ({
      ...pagination,
      ...response.meta
    }))
  }

  const generateReport = async () => {
    const response = await EmailReportService.generateReportFile({
      account, search, from, to, sentType
    })

    if (response.success) {
      storeFileName(response.fileName)
    }
  }

  const handleChange = (e, property, reset) => {
    let value = e.target ? e.target.value : e;
    switch (property) {
      case 'page':
        setPagination(() => ({
          ...pagination,
          page: value
        }))
        break;
      default:
        break;
    }
  }

  const populateTable = () => {
    return (
      <tbody>
        {
          data.map(row => {
            return (<tr key={row.camp_aux + '-' + row.receiver}>
              <td>{row.saved_date}</td>
              <td>{row.camp_aux}</td>
              <td><p>{row.from}</p></td>
              <td><p>{row.receiver}</p></td>
              <td>{row.status}</td>
              <td>{row.sentAt || 'No'}</td>
              <td>{row.doneAt || 'No'}</td>
              <td>{row.opened ? formatMessage({id: 'generals.yes'}) : formatMessage({id: 'generals.no'})}</td>
              <td>{row.clicks || 0}</td>
              <td>{row.credits || 0}</td>
              {/* <td>{row.devices}</td> */}
            </tr>)
          })
        }
      </tbody>
    )
  }

  return (
    <>
      <div className='row'>
        <div className="col s6 offset-s6 m3 offset-m9 l2 offset-l10">
          <a href="#!" className={`waves-effect waves-light btn ${data.length > 0 ? '' : 'disabled'}`}
            onClick = {() => {
              generateReport()
            }}>
            <i className="material-icons right">
              file_download
            </i>
            {formatMessage({ id: 'commons.generate-report' })}
          </a>
        </div>
        <div className="col s12">
          <table className="highlight centered responsive-table">
            <thead>
              <tr>
                <th>{formatMessage({ id: 'generals.date' })}</th>
                <th>{formatMessage({ id: 'report.email.id' })}</th>
                <th>{formatMessage({ id: 'report.email.sender' })}</th>
                <th>{formatMessage({ id: 'report.email.receiver' })}</th>
                <th>{formatMessage({ id: 'report.email.status' })}</th>
                <th>{formatMessage({ id: 'report.email.sent-at' })}</th>
                <th>{formatMessage({ id: 'report.email.done-at' })}</th>
                <th>{formatMessage({ id: 'report.email.opened' })}</th>
                <th>{formatMessage({ id: 'report.email.clicks' })}</th>
                <th>{formatMessage({ id: 'report.email.credits' })}</th>
                {/* <th>{formatMessage({ id: 'report.email.device' })}</th> */}
              </tr>
            </thead>
            {populateTable()}
          </table>
          { pagination.totalPages && pagination.totalPages > 1 ?
            <Paginator
            totalPages={pagination.totalPages}
            page={pagination.page}
            hasPrevPage={pagination.hasPrevPage}
            hasNextPage={pagination.hasNextPage}
            maxPages={10}
            callBack={page => {
              handleChange(page, 'page')
            }}
          /> : null }
        </div>
      </div>
    </>
  )
})
