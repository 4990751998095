///api/v1/rate

import BaseService from './BaseService';
import { endpoint } from '../constants';
import UserService from './UserService';

const AccountService = {

  all: () => {
    const axios = new BaseService({ 'Authorization': UserService.getToken() })
    return axios.get(endpoint('users'));
  },
  updateCredits: (payload) => {
    const axios = new BaseService({ 'Authorization': UserService.getToken() })
    return axios.put(endpoint('client/credits'), payload)
  },
  getAccounts: () => {
    const axios = new BaseService({ 'Authorization': UserService.getToken() })
    return axios.get(endpoint('account'));
  }
}
export default AccountService;
