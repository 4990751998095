export const CHANGE_TO_LANGUAGE = 'CHANGE_TO_LANGUAGE';
export const USER_LOGGED_IN = 'USER_LOGGED_IN';
export const NEW_FILE = 'NEW_FILE';
export const NO_FILES = 'NO_FILES';
//home
export const CURRENT_ACCOUNT = 'CURRENT_ACCOUNT';
//modals
export const SHOW_MODAL = 'SHOW_MODAL'
export const HIDE_MODAL = 'HIDE_MODAL'
//editor template
export const CHANGE_IMAGE_PAYLOAD = 'CHANGE_IMAGE_PAYLOAD';
export const CHANGE_LINK = 'CHANGE_LINK';
export const CHANGE_TEXT = 'CHANGE_TEXT';
