import React from 'react'
export default ({ title, sum }) => {
  return (
    <div key={title} className='col s12 m3'>
      <div className='card-panel'>
        <h2>{sum}</h2>
        <hr></hr>
        <p>{title}</p>
      </div>
    </div>)
}
