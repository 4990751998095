import React, { useEffect, useState } from 'react';
import { injectIntl } from 'react-intl';
import EmailReportService from '../../../services/reports/EmailService';
import Paginator from '../../shared/paginator/container';
import { storeFileName } from '../../shared/utils/localStore';

export default injectIntl(( {intl, account, search, from, to, sentType} ) => {
  const {formatMessage} = intl
  const [data, setData] = useState([])
  const [pagination, setPagination] = useState({
    page: 1,
    limit: 10,
  })
  useEffect(() => {
    getData()
  }, [pagination['page']])
  useEffect(() => {
    if (pagination['page'] !== 1) {
      setPagination(() => ({
        ...pagination,
        page: 1
      }))
    } else {
      getData()
    }
  }, [account, search, from, to, sentType])

  const getData = async () => {
    const response = await EmailReportService.getEmailDetailReport({
      account,
      search,
      from,
      to,
      sentType,
      page: pagination['page'],
      limit: pagination['limit']
    })
    setData(response.result)
    setPagination(() => ({
      ...pagination,
      ...response.meta
    }))
  }

  const generateReport = async () => {
    const response = await EmailReportService.generateMailReportFile({
      account, from, to, sentType
    })

    if (response.success) {
      storeFileName(response.fileName)
    }
  }

  const generateEventReport = async () => {
    const response = await EmailReportService.generateEventsReportFile({
      account, from, to, sentType
    })

    if (response.success) {
      storeFileName(response.fileName)
    }
  }

  const handleChange = (e, property, reset) => {
    let value = e.target ? e.target.value : e;
    switch (property) {
      case 'page':
        setPagination(() => ({
          ...pagination,
          page: value
        }))
        break;
      default:
        break;
    }
  }

  const populateTable = () => {
    return (
      <tbody>
        {
          data.map(row => {
            return (<tr key={row.date + '-' + row.account}>
              <td>{row.date}</td>
              <td>{row.account}</td>
              <td>{row.delivered}</td>
              <td>{row.errors}</td>
              { sentType !== '1' ? <td>{row.test}</td> : null }
              <td>{row.dropped}</td>
              <td>{row.bounced }</td>
              <td>{row.spam }</td>
              <td>{row.blacklisted }</td>
              <td>{row.invalid }</td>
              <td>{row.storage_error }</td>
              <td>{row.mailbox_unavailable }</td>
              <td>{row.system_error }</td>
              <td>{row.opens || 0}</td>
              <td>{row.clicks || 0}</td>
              <td>{row.credits || 0}</td>
              <td>{row.total || 0}</td>
            </tr>)
          })
        }
      </tbody>
    )
  }

  return (
    <>
      <div className='buttons-row'>
        <div className="">
          <a href="#!" className="waves-effect waves-light btn"
            onClick = {() => {
              generateReport()
            }}>
            <i className="material-icons right">
              file_download
            </i>
            {formatMessage({ id: 'commons.generate-report' })}
          </a>
        </div>
        <div className="">
          <a href="#!" className="waves-effect waves-light btn"
            onClick = {() => {
              generateEventReport()
            }}>
            <i className="material-icons right">
              file_download
            </i>
            {formatMessage({ id: 'commons.generate-events-report' })}
          </a>
        </div>
      </div>
      <div className='row'>
        <div className="col s12">
          <table className="highlight centered responsive-table">
            <thead>
              <tr>
                <th>{formatMessage({ id: 'generals.date' })}</th>
                <th>{formatMessage({ id: 'generals.account' })}</th>
                <th>{formatMessage({ id: 'report.email.delivered' })}</th>
                <th>{formatMessage({ id: 'report.email.errors' })}</th>
                { sentType !== '1' ? <th>{formatMessage({ id: 'report.email.test' })}</th> : null}
                <th>{formatMessage({ id: 'report.email.dropped' })}</th>
                <th>{formatMessage({ id: 'report.email.bounced' })}</th>
                <th>{formatMessage({ id: 'report.email.spam' })}</th>
                <th>{formatMessage({ id: 'report.email.blacklisted' })}</th>
                <th>{formatMessage({ id: 'report.email.invalid' })}</th>
                <th>{formatMessage({ id: 'report.email.storage_error' })}</th>
                <th>{formatMessage({ id: 'report.email.mailbox_unavailable' })}</th>
                <th>{formatMessage({ id: 'report.email.system_error' })}</th>
                <th>{formatMessage({ id: 'report.email.opens' })}</th>
                <th>{formatMessage({ id: 'report.email.clicks' })}</th>
                <th>{formatMessage({ id: 'report.email.credits' })}</th>
                <th>{formatMessage({ id: 'report.email.total' })}</th>
              </tr>
            </thead>
            {populateTable()}
          </table>
          { pagination.totalPages && pagination.totalPages > 1 ?
            <Paginator
            totalPages={pagination.totalPages}
            page={pagination.page}
            hasPrevPage={pagination.hasPrevPage}
            hasNextPage={pagination.hasNextPage}
            maxPages={10}
            callBack={page => {
              handleChange(page, 'page')
            }}
          /> : null }
        </div>
      </div>
    </>
  )
})
