import axios from 'axios';
import swal from 'sweetalert';
import $ from 'jquery';

class BaseService {
  constructor(headers) {
    let service = axios.create({
      // withCredentials: true,
      crossdomain: true,
      headers: {
        ...headers,
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      }
    });
    this.service = service;
  }

  fetch = (url, data) => {
    //LoadingEvent.emit("$loading", true);
    $('.container-ring').show();
    console.log(url, data);
    data.url = url;
    return this.service(data)
      .then(response => {
        //LoadingEvent.emit("$loading", false);
        $('.container-ring').hide();
        return this.handleResponse(response);
      })
      .catch(e => {
        //LoadingEvent.emit("$loading", false);
        $('.container-ring').hide();
        return this.handleReject(e);
      });
  };

  get(path) {
    return this.fetch(path, {
      method: "GET"
    });
  }

  patch(path, payload) {
    return this.fetch(path, {
      method: "PATCH",
      data: payload
    });
  }

  post(path, payload) {
    return this.fetch(path, {
      method: "POST",
      data: payload
    });
  }

  delete(path, payload) {
    return this.fetch(path, {
      method: "DELETE",
      data: payload
    });
  }

  put(path, payload) {
    return this.fetch(path, {
      method: "PUT",
      data: payload
    });
  }

  handleResponse(response) {
    console.log(response);
    if (response.status === 200) {
      console.log(response.data);
      return Promise.resolve(response.data);
    }
  }

  handleReject(err) {
    // checks for network error
    if (!err.response) {
      // show alert saying there's a network error
      return swal({
        title: 'Error en conectividad de red',
        text: 'No podrá interactuar con la aplicación, asegurese de tener conexión estable.',
        icon: 'warning',
        closeOnClickOutside: false
      }).then(() => Promise.reject(err));
    }

    if (err.response.status === 401) {
      //UserService.removeSession();
      localStorage.removeItem('user');
      window.location.href = "/login";
    } else {
      console.log('error de respuesta', err.response);
      return Promise.reject(err.response.data);
    }
  }
}

export default BaseService;
