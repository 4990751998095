import { USER_LOGGED_IN } from '../actionTypes';

const initialState = {
  user: null
};

export default function(state = initialState, action) {
  // console.log(action.user,'action')
  switch (action.type) {
    case USER_LOGGED_IN: {
      // console.log('ACTUALIZANDO USUARIO REDUX', action.user.user);
      return {
        ...state,
        user: action.user
      };
    }
    default:
      return state;
  }
}
