import React, { Component } from "react";
import { injectIntl } from 'react-intl';
import './paginator.scss';
class Paginator extends Component {
  constructor(props) {
    super(props);
    this.state = {}
  }
  componentWillReceiveProps() {
    if (!this.state.paintPages.includes(this.props.page) || this.props.totalPages !== this.state.totalPages) {
      this.setState({
        totalPages: this.props.totalPages,
        paintPages:[]
      }, () => this.setPages())
    }
  }
  componentDidMount() {
    let { page, totalPages } = this.props;
    this.setState({ page, totalPages }, () => this.setPages())
  }

  setPages = () => {
    let { paintPages } = this.state;
    let { page, maxPages, totalPages } = this.props
    let pages = []

    if (totalPages < page)
      return null
    for (let index = 0; index < Math.min(maxPages, totalPages); index++) {
      pages.push(index)
    }
    let pagesToShow = pages
      .map(p => page + p <= totalPages ? page + p : undefined)
      .filter(e => e !== undefined);
    if (page !== this.state.page) {
      this.setState({ page })
    }
    if (page === 1 && (paintPages === undefined || paintPages.length !== pages.length)) {
      this.setState({ paintPages: pagesToShow })
    } else {
      if (!paintPages.includes(page))
        this.setState({ paintPages: pagesToShow })
    }
  }

  pagePopulate = (page) => {
    let { paintPages } = this.state;
    this.setPages();
    let draw = paintPages.map(p => <li
      key={p}
      className={p === page ? 'active' : 'waves-effect'}
      onClick={e => this.changePage(null, p)}
    >
      <a href="#!" htmlFor="" >
        {p}
      </a>
    </li>)
    return draw
  }
  changePage = (mov, currentPage = null) => {
    let { callBack } = this.props
    let { page } = this.state;
    let pageUpdated;
    switch (mov) {
      case 'quit':
        pageUpdated = page - 1
        break;
      case 'push':
        pageUpdated = page + 1
        break;
      default:
      case null:
        pageUpdated = currentPage
        break;
    }
    this.setState({
      page: pageUpdated
    }, () => callBack(this.state.page))
  }
  render() {
    let { page, paintPages } = this.state;
    let { hasPrevPage, hasNextPage, totalPages } = this.props;
    return page && paintPages && totalPages > 1 ? (
      <div id='paginator-container' className='row'>
        <ul className='pagination'>
          <li
            className={hasPrevPage ? 'waves-effect' : 'disabled'}
            onClick={e => { hasPrevPage && this.changePage('quit') }}
          >
            <a href='#!'>
              <i className='material-icons'>chevron_left</i>
            </a>
          </li>
          {this.pagePopulate(page, totalPages)}
          <li
            className={hasNextPage ? 'waves-effect' : 'disabled'}
            onClick={e => { if (hasNextPage) { this.changePage('push') } }}
          >
            <a href='#!'>
              <i className='material-icons'>chevron_right</i>
            </a>
          </li>
        </ul>
      </div>
    ) : null
  }
}
export default injectIntl(Paginator);
