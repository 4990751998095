import React, { useState, useEffect } from 'react';
import { injectIntl } from 'react-intl';
import Paginator from '../../shared/paginator/container';
import { storeFileName } from '../../shared/utils/localStore';
import EmailReportService from '../../../services/reports/EmailService';

export default injectIntl(( {intl, account, from, to} ) => {
  const {formatMessage} = intl
  const [data, setData] = useState([])
  const [pagination, setPagination] = useState({
    page: 1,
    limit: 5,
  })
  useEffect(() => {
    getData()
  }, [pagination['page']])
  useEffect(() => {
    if (pagination['page'] !== 1) {
      setPagination(() => ({
        ...pagination,
        page: 1
      }))
    } else {
      getData()
    }
  }, [account, from, to])
  const getData = async () => {
    const response = await EmailReportService.getOpenedLinksReport({
      account,
      from,
      to,
      page: pagination['page'],
      limit: pagination['limit']
    })
    setData(response.result)
    setPagination(() => ({
      ...pagination,
      ...response.meta
    }))
  }
  const handleChange = (e, property, reset) => {
    let value = e.target ? e.target.value : e;
    switch (property) {
      case 'page':
        setPagination(() => ({
          ...pagination,
          page: value
        }))
        break;
      default:
        break;
    }
  }
  const generateReport = async () => {
    const response = await EmailReportService.generateReportFile({
      account, from, to
    }, 'links')

    if (response.success) {
      storeFileName(response.fileName)
    }
  }
  const populateTable = () => {
    return (
      <tbody>
        {
          data.map((row, i) => {
            return (<tr key={i + '-' + row.url}>
              <td>{row.date}</td>
              <td>{row.urls}</td>
              <td>{row.count}</td>
            </tr>)
          })
        }
      </tbody>
    )
  }
  return (
    <>
      <div className="row">
        <div className="col s12 m10">
          <h4>{formatMessage({id:'report.email.links-clicked.title'})}</h4>
        </div>
        <div className="col s6 offset-s6 m2">
          { data.length ? <a href="#!" className="waves-effect waves-light btn"
            onClick = {() => {
              generateReport()
            }}>
            <i className="material-icons">
              file_download
            </i>
          </a> : null }
        </div>
      </div>
      <div className='row'>
        <table className="highlight centered responsive-table">
          <thead>
            <tr>
              <th>{formatMessage({ id: 'generals.date' })}</th>
              <th>{formatMessage({ id: 'report.email.url' })}</th>
              <th>{formatMessage({ id: 'report.email.count' })}</th>
            </tr>
          </thead>
          {populateTable()}
        </table>
        { pagination.totalPages && pagination.totalPages > 1 ?
          <Paginator
          totalPages={pagination.totalPages}
          page={pagination.page}
          hasPrevPage={pagination.hasPrevPage}
          hasNextPage={pagination.hasNextPage}
          maxPages={5}
          callBack={page => {
            handleChange(page, 'page')
          }}
        /> : null }
      </div>
    </>
  )
})