import './rates.scss'
import React, { Component } from "react";
import { injectIntl } from 'react-intl';
import M from "materialize-css";
import swal from 'sweetalert';
import RatesService from '../../../services/RatesService';
import RatesUpsertModal from '../../shared/modals/rateEdit'

class Rates extends Component {
  constructor(props) {
    super(props)
    this.state = {
      rows: [],
      showUpsertModal: false,
      showDisplayModal: false,
      rate: null,
      action: 'update'
    }
  }
  componentDidMount = async () => {
    await this.getRates()
    let elems = document.querySelectorAll('.dropdown-trigger');
    M.Dropdown.init(elems, {inDuration: 300, outDuration: 300});
  }
  getRates = async () => {
    let response = await RatesService.get()
    let { result } = response;

    this.setState({
      rows: result.rates
    })
  }

  async closeModal(newRate) {
    const { formatMessage } = this.props.intl

    this.setState({
      showUpsertModal: false,
      account: null
    })

    if (newRate) {
      let upsert = null;

      if (newRate._id) {
        upsert = RatesService.update(newRate._id, newRate);
      } else {
        let rate = {
          ...newRate,
          rates: newRate.rates.map(el => {
            return {
              operator: el.operator,
              price: el.price
            }
          })
        }
        upsert = RatesService.create(rate);
      }

      upsert.then((response) => {
        if (response.success) {
          swal({
            title: formatMessage({ id: 'commons.success' }),
            text: newRate._id ? formatMessage({ id: 'rates.update.success' }) + '!' : formatMessage({ id: 'rates.create.success' }) + '!',
            icon: 'success'
          })
        }
      }).catch(err => {
        swal({
          title: formatMessage({ id: 'commons.error' }),
          text: newRate._id ? formatMessage({ id: 'rates.update.error' }) + '!' : formatMessage({ id: 'rates.create.error' }) + '!',
          icon: 'error'
        })
      }).finally(() => {
        this.getRates();
      });
    }
  }

  populateTable() {
    let { rows } = this.state;
    const { formatMessage } = this.props.intl;
    let tableRows = [];
    for (let index = 0; index < rows.length; index++) {
      const row = rows[index];
      tableRows.push(
        <tr key={row['_id']}>
          <td>{ formatMessage({id: 'generals.' + row['type']}) }</td>
          <td>{row['provider']}</td>
          <td>{row['masked'] ? formatMessage({id:'generals.yes'}) : formatMessage({id:'generals.no'})}</td>
          <td>{row['active'] ? formatMessage({id:'generals.yes'}) : formatMessage({id:'generals.no'})}</td>
          <td>{row['min_vol']}</td>
          <td>{row['max_vol'] !== 0 ? row['max_vol'] : formatMessage({id:'generals.no-limit'})}</td>
          <td>
            <a className='dropdown-trigger btn' href='#!' data-target={'actions-' + row['_id']}>
              {formatMessage({id:'accounts.actions'})}
            </a>
            <ul id={'actions-' + row['_id']} className='dropdown-content'>
              <li><a href="#!" onClick={(e) => this.upsertRate(e, 'show', row)}>{ formatMessage({id: 'commons.show'}) }</a></li>
              <li><a href="#!" onClick={(e) => this.upsertRate(e, 'upsert', row)}>{ formatMessage({id: 'commons.update'}) }</a></li>
            </ul>
          </td>
        </tr>
      )
    }
    return (
      <tbody>
        {tableRows}
      </tbody>
    )
  }

  showRate(e, rate) {
    e.preventDefault()
    this.setState({
      showDisplayModal: true,
      rate: rate
    })
  }

  upsertRate(e, action, rate = null) {
    e.preventDefault()
    this.setState({
      showUpsertModal: true,
      rate: rate,
      action: action
    })
  }

  render() {
    const { formatMessage } = this.props.intl
    const { rate, action, showUpsertModal } = this.state

    return (
      <div id="rates-component">
        <div className="row">
          <div className="input-field col s6">
            <a href="#!" className={`waves-effect waves-light btn-small`}
              onClick={(e) => this.upsertRate(e, 'upsert')}>
                <i className="material-icons left">add</i>
                {formatMessage({id: 'commons.add'})}
            </a>
          </div>
        </div>
        <div className="row">
          <table className="highlight centered responsive-table">
            <thead>
              <tr>
                <th>{formatMessage({ id: 'generals.type' })}</th>
                <th>{formatMessage({ id: 'generals.client-provider' })}</th>
                <th>{formatMessage({ id: 'generals.masked' })}</th>
                <th>{formatMessage({ id: 'generals.active' })}</th>
                <th>{formatMessage({ id: 'generals.min-vol' })}</th>
                <th>{formatMessage({ id: 'generals.max-vol' })}</th>
                <th>{formatMessage({ id: 'commons.actions' })}</th>
              </tr>
            </thead>
            { this.populateTable() }
          </table>
        </div>
        { showUpsertModal ?
          <RatesUpsertModal
            rate={rate}
            action={action}
            callBack={rate => {
              this.closeModal(rate)
            }}/> : null
         }
         {/* { showDisplayModal ?
           <RatesShowModal
             rate={rate}
             callBack={() => {
               this.closeModal(null)
             }}/> : null
          } */}
      </div>
    )
  }
}
export default injectIntl(Rates)
