import $ from 'jquery';
import M from "materialize-css";
import moment from 'moment';
import React, { Component } from "react";
import { injectIntl } from 'react-intl';
import store from '../../../../redux/store';
import MessageService from '../../../../services/reports/MessageService';
import UserService from '../../../../services/UserService';
import './directo-report.scss';

class ClientReport extends Component {
  constructor(props) {
    super(props);
    this.state = {
      accounts: [],
      accountSelected: undefined,
      date: undefined,
      rows: []
    }
  }
  async componentDidMount() {
    M.AutoInit();
    let elements = document.querySelectorAll('.datepicker');
    await this.setState({ user: UserService.getUser() || 'indefinido' })
    const date = moment().format('YYYY-MM-DD hh:mm:ss');
    $('.datepicker').on('change', e => this.handleChange(e))
    M.Datepicker.init(elements, {
      maxDate: new Date(date),
      autoClose: true
    })
    let elems = document.querySelectorAll('.dropdown-trigger');
    M.Dropdown.init(elems, {inDuration: 300, outDuration: 300});
    this.getReports();
  }


  populateSelectAccounts = () => {
    let { accounts } = this.state;
    let optionItems = [<option value={''} key='*' defaultValue>Todos</option>]
    for (let index = 0; index < accounts.length; index++) {
      const account = accounts[index];
      optionItems.push(<option value={account} key={account}>{account}</option>)
    }
    return (
      <select
        id='accounts'
        onChange={this.handleChange}
      >
        {optionItems}
      </select>
    )
  }

  handleChange = (e) => {
    switch (e.target.id) {
      case 'accounts':
        this.setState({
          accountSelected: e.target.value.trim()
        }, () => this.getReports())
        break;
      case 'datepicker':
        let dateString = e.target.value.trim();
        let date = moment(new Date(dateString)).format('YYYY-MM-DD');
        date = moment().diff(date, 'days') > 0 ? date : undefined
        this.setState({
          date
        }, () => this.getReports())
        break;
      default:
        break;
    }
  };

  getReports = async () => {
    let { user, date, accountSelected } = this.state;
    let payload = { cid: user.company_id, account: accountSelected }
    if (date)
      payload = {
        ...payload,
        from: date,
        to: date
      }
    let response = await MessageService.message(payload)
    let { result } = response;
    let accounts = [];
    for (let index = 0; index < result.length; index++) {
      const element = result[index];
      if (!accounts.includes(element.user))
        accounts.push(element.user)
    }

    this.setState({
      accounts,
      rows: result
    }, () => M.FormSelect.init(document.querySelectorAll('select'), {}))
  }
  getTotals(field) {
    let sum = 0;
    const { rows } = this.state;

    rows.forEach(record => {
      sum += record[field];
    });

    return sum;
  }
  populateTable = () => {
    let { rows } = this.state;
    let tableRows = [];
    for (let index = 0; index < rows.length; index++) {
      const row = rows[index];
      tableRows.push(
        <tr key={row['date'] + '-' + row['user']}>
          <td>{row['date'] || ''}</td>
          <td>{row['user'] || ''}</td>
          <td>{row['successful'] || 0}</td>
          <td>{row['timeRestricted'] || 0}</td>
          <td>{row['error'] || 0}</td>
          <td>{row['invalid'] || 0}</td>
          <td>{row['total'] || 0}</td>
          <td>{row['credits'] || 0}</td>
        </tr>
      )
    }
    tableRows.push(
      <tr key={'total'}>
          <td></td>
          <td align="right"><b>Total</b></td>
          <td><b>{this.getTotals('successful')}</b></td>
          <td><b>{this.getTotals('timeRestricted')}</b></td>
          <td><b>{this.getTotals('error')}</b></td>
          <td><b>{this.getTotals('invalid')}</b></td>
          <td><b>{this.getTotals('total')}</b></td>
          <td><b>{this.getTotals('credits')}</b></td>
        </tr>
    )
    return (
      <tbody
      >
        {tableRows}
      </tbody>
    )
  }
  requestReport(e, responses = false) {
    e.preventDefault()
    const { formatMessage } = this.props.intl
    let { user, accountSelected, date } = this.state;

    if (!accountSelected) {
      M.toast({html: formatMessage({id: 'commons.reports.missing-account'})})
      return
    }

    let payload = { cid: user.company_id, account: accountSelected }

    if (date) {
      payload = {
        ...payload,
        from: date,
        to: date
      }
    }

    let request = null;

    if (responses) {
      request = MessageService.responsesFile(payload)
    } else {
      request = MessageService.detailsFile(payload)
    }

    request.then(async (response) => {
      if (response.success) {
        this.storeFileName(response.fileName)
        store.dispatch({
          type:'NEW_FILE',
          file: response.fileName
        })

        M.toast({html: formatMessage({id: 'commons.reports.generating-report'})})
      } else {
        M.toast({html: formatMessage({id: 'commons.reports.no-records'})})
      }
    }).catch(err => {
      M.toast({html: formatMessage({id: 'commons.error'})})
    }).finally(() => {
      this.loading = false;
    });
  }
  storeFileName(fileName) {
    const pendingFiles = localStorage.getItem('pendingFiles');
    let files = [];

    if (pendingFiles) {
      files = JSON.parse(pendingFiles)

      if (!files.includes(fileName)) {
        files.push(fileName)
      }
    } else {
      files.push(fileName)
    }

    localStorage.setItem('pendingFiles', JSON.stringify(files));
  }
  render() {
    const { formatMessage } = this.props.intl;
    return (
      <div id="directo-report-component">
        <div className="row">
          <div className="input-field col s12 m6 l3">
            <label>
              {formatMessage({id: 'generals.date' })}
            </label>
            <input
              type="text"
              className="datepicker"
              id="datepicker"
            >
            </input>
          </div>
          <div className=" input-field col s12 m6 l3">
            {this.populateSelectAccounts()}
          </div>
          <div className="input-field col s12 m6 l3">
            <a className='dropdown-trigger btn' href='#!' data-target='dropdown1'>{formatMessage({id:'commons.reports'})}</a>
            <ul id='dropdown1' className='dropdown-content'>
              <li><a href="#!" onClick={(e) => this.requestReport(e, false)}>{formatMessage({id:'commons.reports.details'})}</a></li>
              <li><a href="#!" onClick={(e) => this.requestReport(e, true)}>{formatMessage({id:'commons.reports.answers'})}</a></li>
            </ul>
          </div>
          <div className=" input-field col s12 m6 l3">
            <button href=""
              onClick={this.getReports}
              className="btn-floating btn-md waves-effect waves-light">
              <i className="material-icons">
                refresh
              </i>
            </button>
          </div>
        </div>
        <div className="row">
          <table className="highlight centered responsive-table">
            <thead>
              <tr>
                <th>{formatMessage({ id: 'generals.date' })}</th>
                <th>{formatMessage({ id: 'generals.user' })}</th>
                <th>{formatMessage({ id: 'generals.successful' })}</th>
                <th>{formatMessage({ id: 'generals.restricted' })}</th>
                <th>{formatMessage({ id: 'generals.errors' })}</th>
                <th>{formatMessage({ id: 'generals.invalid' })}</th>
                <th>{formatMessage({ id:'generals.total'})}</th>
                <th>{formatMessage({ id: 'generals.credits' })}</th>
              </tr>
            </thead>
            {this.populateTable()}
          </table>
        </div>
      </div>
    )
  }
}
export default injectIntl(ClientReport);
