import M from "materialize-css";
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { injectIntl } from 'react-intl';
import CompanyService from '../../../services/CompanyService';
import DetailedReport from './detail';
import './reports.scss';

export default injectIntl(({ intl }) => {
  const { formatMessage } = intl
  const [companyAccounts, setCompanyAccounts] = useState([])
  const [account, setAccount] = useState('')
  const [from, setFrom] = useState(moment().format('YYYY-MM-DD'))
  const [search, setSearch] = useState('')
  const [sentType, setSentType] = useState(1)

  useEffect(() => {
    init()
  }, [])
  const init = async () => {
    let response = await CompanyService.getEmailAccounts()
    let accounts = response.result.map(account => account.user)
    setCompanyAccounts(accounts)
    setMaterialize()
  }
  const handleChange = (e, property, reset) => {
    let value = e.target.value;
    switch (property) {
      case 'search':
        setSearch(value)
        break;
      default:
        break;
    }
  }
  const setMaterialize = () => {
    let fromPicker = document.querySelectorAll('#fromDate');
    M.AutoInit();
    M.Datepicker.init(fromPicker, {
      autoClose: true,
      format: 'yyyy-mm-dd',
      minDate: null,
      maxDate: moment().toDate(),
      setDefaultDate: true,
      defaultDate: moment().toDate(),
      onSelect: async date => {
        date = moment(date).format('YYYY-MM-DD')
        console.log(date, 'one')
        setFrom(date)
      }
    })
  }
  const populateSelectAccounts = () => {
    let optionItems = [<option value='' key='*' defaultValue>Todos</option>]
    companyAccounts.map(account => optionItems.push(<option value={account} key={account}>{account}</option>));
    return (
      <select
        id='companyAccounts'
        onChange={e => {
          setAccount(e.target.value.trim())
        }}>
        {optionItems}
      </select>
    )
  }
  const changeSentType = value => {
    console.log(value);
    setSentType(value)
  }
  return (
    <div id="email-report">
      <div className="row">
        <div className="input-field col s12 m3 datepicker-container">
          <label>
            {formatMessage({id: 'generals.date' })}
          </label>
          <input
            type="text"
            className="datepicker"
            id="fromDate"
          >
          </input>
        </div>
        <div className="input-field col s12 m3 searchField-container">
          <input
            type="text"
            className="searchField"
            id="search"
            onKeyUpCapture= { e => { handleChange(e, 'search', true) } }></input>
          <label htmlFor="search">Criterio de búsqueda</label>
        </div>
        {/* <div className="input-field col s12 m3 datepicker-container">
          <input
            type="text"
            className="datepicker"
            id="toDate"
          >
          </input>
        </div> */}
        <div className="input-field col s12 m4 select-container">
          {populateSelectAccounts()}
          <label>{formatMessage({ id: 'generals.accounts' })}</label>
        </div>
        <div className="input-field col s12 m2 select-container">
          <select
            id='sentType'
            onChange={e => {
              changeSentType(e.target.value.trim())
            }}>
            <option value='0' key='0'>{formatMessage({id: 'commons.all'})}</option>
            <option value='1' key='1' selected={true} defaultValue>{formatMessage({id: 'commons.only-production'})}</option>
            <option value='2' key='2'>{formatMessage({id: 'commons.only-test'})}</option>
          </select>
          <label>{formatMessage({ id: 'generals.sent-type' })}</label>
        </div>
      </div>
      <div className="row">
        <DetailedReport
          account={account}
          search={search}
          from={from}
          to={from}
          sentType={sentType} />
      </div>
    </div>
  )
})
