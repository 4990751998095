import React, { Component } from 'react';
import { injectIntl } from 'react-intl';
import JoditEditor from 'jodit-react';
import store from '../../../../redux/store'
class CustomJoditEditor extends Component {
  subscribtion = null
  constructor(props) {
    super(props)
    this.state = {
      content: '',
      config: {
        readonly: false // all options from https://xdsoft.net/jodit/doc/
      }
    }
  }
  componentDidMount() {
    let textComponent = store.getState().editorTemplate.textComponent
    let element = Object.keys(textComponent).find(e => e === this.props.id)
    if (textComponent[element]) {
      this.setState({
        content: textComponent[element].text
      })
    }
  }

  save = () => {
    let { callBack } = this.props
    callBack(this.state.content)
  }

  render() {
    const { formatMessage } = this.props.intl
    let { content, config } = this.state
    return (
      <div className='row' id='custom-jodit-editor'>
          <JoditEditor
            value={content}
            config={config}
            tabIndex={1} // tabIndex of textarea
            onChange={newContent => this.setState({ content: content + newContent })} // preferred to use only this option to update the content for performance reasons
          />
        <div className='block-action-button-container'>
          <button
            className='btn'
            disabled={content !== '' ? '' : 'disabled'}
            onClick={this.save}
          >
            {formatMessage({ id: 'commons.save' })}
            <i className="material-icons right">send</i>
          </button>
        </div>
      </div>
    );
  }
}

export default injectIntl(CustomJoditEditor);
