import M from "materialize-css";
import "materialize-css/dist/css/materialize.min.css";
import React, { Component } from "react";
import { BrowserRouter, Redirect, Route, Switch } from "react-router-dom";
import UserService from "../../services/UserService";
// import CampaignsModule from '../sms/campaigns/container'
// import RcsModule from '../rcs/messages/container'
import EmailDashboard from '../emails/dashboard/container';
import { default as EmailList } from '../emails/list/container';
import { default as EmailReport } from '../emails/reports/container';
import SidebarEditor from '../emails/templateEditor/SidebarEditor';
import Footer from "../footer/footer";
import Header from "../header/header";
import Home from "../home/home";
import Accounts from '../sms/accounts/container';
import MessagesModule from '../sms/messages/container';
import Rates from '../sms/rates/container';
import ClientReport from '../sms/reports/client/container';
import DirectoReport from '../sms/reports/directo/container';
import "./layout.scss";
// import TemplateEditor from '../emails/templateEditor/container'
class Layout extends Component {
  componentDidMount() {
    M.AutoInit();
  }
  redirect(url) {
    return <Redirect
      to={{
        pathname: '/' + url,
        state: {
          from: this.props.location
        }
      }}
    />
  }
  render() {
    return (
      [
        <Header key={'header'}>
        </Header>,
        <main key={'main'} className='main-container-layout'>
          <div className='container-ring'>
            <div className="lds-ring"><div></div><div></div><div></div><div></div></div>
          </div>
          <div className="container">
            <BrowserRouter>
              <Switch>
                <Route
                  exact
                  path={this.props.match.path + ""}
                  component={() => {
                    window.location.href = "/home";
                  }}
                  />
                <Route
                  exact
                  path={this.props.match.path + "home"}
                  component={Home} />
                <Route
                  exact
                  path={this.props.match.path + "sms/report/directo"}
                  render={() => {
                    if (UserService.hasRole(['root', 'internal_administrator', 'reports'])) {
                      return (
                        <DirectoReport {...this.props} roles={['root', 'internal_administrator', 'reports']}/>
                      )
                    } else {
                      return this.redirect('home')
                    }
                  }
                }/>
                <Route
                  exact
                  path={this.props.match.path + "sms/accounts"}
                  render={() => {
                    if (UserService.hasRole(['root', 'internal_administrator'])) {
                      return (
                        <Accounts {...this.props} roles={['root', 'internal_administrator']}/>
                      )
                    } else {
                      return this.redirect('home')
                    }
                  }
                }/>
                <Route
                  exact
                  path={this.props.match.path + "sms/rates"}
                  render={() => {
                    if (UserService.hasRole(['root', 'internal_administrator'])) {
                      return (
                        <Rates {...this.props} roles={['root', 'internal_administrator']}/>
                      )
                    } else {
                      return this.redirect('home')
                    }
                  }
                }/>
                <Route
                  exact
                  path={this.props.match.path + "sms/report/client"}
                  render={() => {
                    if (UserService.hasRole(['client', 'client_administrator'])) {
                      return (
                        <ClientReport {...this.props} roles={['client', 'client_administrator']}/>
                      )
                    } else {
                      return this.redirect('home')
                    }
                  }
                }/>
                <Route
                  exact
                  path={this.props.match.path + "sms/messages"}
                  component={MessagesModule} />
                {/* <Route
                  exact
                  path={this.props.match.path + "sms/campaigns"}
                  component={CampaignsModule} />
                <Route
                  exact
                  path={this.props.match.path + "rcs/messages"}
                  component={RcsModule} /> */}
                {/* <Route
                  exact
                  path={this.props.match.path + "email/template/create"}
                  render={() => {
                    if (UserService.hasRole(['root'])) {
                      return (
                        <TemplateEditor {...this.props} roles={['root']} />
                      )
                    } else {
                      return this.redirect('home')
                    }
                  }
                  } /> */}
                <Route
                  exact
                  path={this.props.match.path + "email/"}
                  component={EmailDashboard}/>
                <Route
                  exact
                  path={this.props.match.path + "email/list"}
                  component={EmailList} />
                <Route
                  exact
                  path={this.props.match.path + "email/reports"}
                  component={EmailReport} />

                {/* always the last route   */}
                <Route
                  path={this.props.match.path + "**"}
                  component={() => {
                    let { pathname } = this.props.location;
                    pathname = pathname.substr(1);
                    return <b>NOT FOUND {pathname}</b>;
                  }}
                />
              </Switch>
            </BrowserRouter>
          </div>
        </main>,
        <SidebarEditor></SidebarEditor>,
        <Footer key={'footer'} >
        </Footer>
      ]
    );
  }
}
export default Layout;
