import { CHANGE_IMAGE_PAYLOAD, CHANGE_LINK, CHANGE_TEXT } from '../actionTypes';

const initialState = {
  imageComponent: {
    source:'https://increasify.com.au/wp-content/uploads/2016/08/default-image.png',
    alt: '',
    url: '',
    id: ''
  },
  textComponent:{

  }
};

export default function (state = initialState, action) {
  switch (action.type) {
    case CHANGE_IMAGE_PAYLOAD: {
      console.log(action)
      let source = action.source
      let alt = action.alt
      let url = action.url
      let id = action.id
      return {
        ...state,
        imageComponent: {
          ...state.imageComponent,
          [id]: {
            source,
            alt,
            url
          }
        }
      };
    }
    case CHANGE_LINK: {
      let link = action.link
      let id = action.id
      return {
        ...state,
        image: {
          ...state.image,
          link,
          id
        }
      };
    }
    case CHANGE_TEXT: {
      let text = action.text
      let id = action.id
      return {
        ...state,
        textComponent: {
          ...state.textComponent,
          [id]:{
            id,
            text
          }
        }
      };
    }
    default:
      return state;
  }
}
