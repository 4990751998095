import React, { Component } from "react";
import { injectIntl } from 'react-intl';
import M from "materialize-css";
import "materialize-css/dist/css/materialize.min.css";

class Modal extends Component {
  constructor(props) {
    super(props)
    this.state = {
      credits: undefined,
      validCredits: false
    }
  }
  componentDidMount() {
    const { callBack } = this.props
    const options = {
      onOpenStart: () => {
      },
      onOpenEnd: () => {
      },
      onCloseStart: () => {
      },
      onCloseEnd: () => {
        let { credits, validCredits } = this.state
        if (validCredits) {
          callBack(credits)
        } else {
          callBack(null)
        }
      },
      inDuration: 250,
      outDuration: 250,
      opacity: 0.5,
      dismissible: false,
      startingTop: "4%",
      endingTop: "10%"
    };
    const modal = M.Modal.init(this.Modal, options);
    modal.open()
  }

  changeCreditsAmount(event) {
    let value = event.target.value
    let credits = value.trim()
    let isValid = false

    if (!isNaN(credits) && credits > 0) {
      isValid = true
    }

    this.setState({
      credits: value.trim(),
      validCredits: isValid
    })
  }

  render() {
    const { action } = this.props
    const { formatMessage } = this.props.intl
    let { validCredits } = this.state
    return (
      <>
        <div
          ref={Modal => {
            this.Modal = Modal;
          }}
          id="modal1"
          className="modal"
        >
          <div className="modal-content">
            <h4>{ action === 'add' ? formatMessage({id: 'modal.add-credits'}) : formatMessage({id: 'modal.change-credits'}) }</h4>
            <p>{ action === 'add' ? formatMessage({id: 'modal.add-description'}) : formatMessage({id: 'modal.change-description'}) }</p>
            <div className="row">
              <div className=" input-field col s12 m6 l4">
                <input id="credits" type="text" className="validate"
                  onKeyUpCapture = {
                    e => {
                        this.changeCreditsAmount(e)
                    }
                  } />
              </div>
            </div>
          </div>
          <div className="modal-footer">
            <a href="#!" className="modal-close waves-effect waves-red btn-flat">
              {formatMessage({id: 'modal.cancel'})}
            </a>
            <a href="#!" className={`modal-close waves-effect waves-green btn-flat btn btn-success ${validCredits ? '' : 'disabled'}`}>
              { action === 'add' ? formatMessage({id: 'modal.add'}) : formatMessage({id: 'modal.change'}) }
            </a>
          </div>
        </div>
      </>
    );
  }
}

export default injectIntl(Modal);