import React, { Component } from "react";
import './App.scss';
import { BrowserRouter, Switch } from 'react-router-dom';
import Login from './components/login/login';
import Layout from './components/layout/layout';
import "materialize-css/dist/css/materialize.min.css";
import M from "materialize-css";
import {
  ProtectedRoute
} from './protected.route'
class App extends Component {

  componentDidMount() {
    M.AutoInit();
  }

  render() {
    return (
      <BrowserRouter>
        <Switch>
          <ProtectedRoute path='/login'
            component={Login}
          />
          <ProtectedRoute
            path='/'
            component={Layout}
          />
        </Switch>
      </BrowserRouter>
    )
  }
}

export default App;
