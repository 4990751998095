import { NEW_FILE, NO_FILES } from '../actionTypes';

const initialState = {
  pendingFiles: []
}

export default (state = initialState, action) => {
  switch (action.type) {
    case NEW_FILE: {
      return {
        ...state,
        pendingFiles: action.file
      }
    }
    case NO_FILES: {
      return {
        ...state,
        pendingFiles: []
      }
    }
    default:
      return state;
  }
}
