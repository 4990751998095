import React, { Component } from "react";
import { injectIntl } from 'react-intl';
import M from "materialize-css";
import $ from 'jquery'
import './ImageDetailEditor.scss'
import Image from '../../viewers/Image'
import ImageUploader from '../ImageUploader/ImageUploader'
import TemplateEditorService from '../../../../services/TemplateEditorService'
import store from '../../../../redux/store'

class ImageDetailEditor extends Component {
  constructor(props) {
    super(props)
    this.state = {
      showUploadImageModal: false,
      source: undefined,
      alt: undefined,
      url: undefined,
    }
  }

  componentDidMount() {
    $(document).ready(() => {
      M.updateTextFields();
    })
    let imageComponent = store.getState().editorTemplate.imageComponent
    let element = Object.keys(imageComponent).find(e => e === this.props.id)
    if (imageComponent[element]) {
      this.setState({
        source: imageComponent[element].source,
        alt: imageComponent[element].alt,
        url: imageComponent[element].url
      })
    }
  }

  validateUrl(value) {
    return /^(?:(?:(?:https?|ftp):)?\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:[/?#]\S*)?$/i.test(value);
  }

  changeUrl(event) {
    let value = event.target.value
    let link = value.trim()
    let isValid = false

    if (this.validateUrl(link)) {
      isValid = true
    }

    this.setState({
      link,
      validLink: isValid
    })
  }

  openUploadImageModal() {
    this.setState({
      showUploadImageModal: true
    })
  }

  changeImageUrl(event) {
    const url = event.target.value
    console.log(url)
    if (this.validateUrl(url)) {
      console.log(url, 'is valid')
      this.setState({
        url
      })
    }
  }

  changeImageAlt(event) {
    this.setState({
      alt: event.target.value
    })
  }

  cancel() {
    const { cancel } = this.props

    cancel()
  }

  save() {
    const { callback } = this.props
    const payload = {
      source: this.state.source,
      alt: this.state.alt,
      url: this.state.url
    }

    callback(payload)
  }

  setImageSource(url) {
    this.setState({
      source: url
    })
  }

  onChangeHandler=event=>{
    const { formatMessage } = this.props.intl
    const image = event.target.files[0]
    const data = new FormData()
    data.append('file', image)
    data.append('templateId', this.props.id)
    data.append('previousImage', this.state.source)

    if ((/\.(jpg|png|gif|jpeg)$/i).test(image.name)) {
      TemplateEditorService.uploadImage(data).then(response => {
        if (response.success) {
          this.setState({
            source: response.result.imgUri
          })
        }
      })
    } else {
      M.toast({
        html: formatMessage({id: 'image.error_no-image'})
      })
    }
  }

  render() {
    const { formatMessage } = this.props.intl

    return (
      <div className="row image-form">
        <form className="col s12">
          <div className="row">
            <div className="col-12">
              <h3>{ formatMessage({id: 'generals.image-details_header'}) }</h3>
            </div>
          </div>
          <div className="row">
            <div className="input-field col s12">
              <i className="material-icons prefix">insert_link</i>
              <input id="image_url"
                type="text"
                className="validate"
                value={this.state.url}
                onBlur = {
                  e => {
                      this.changeImageUrl(e)
                  }
                } />
              <label className="active" for="image_url">{ formatMessage({id: 'image.edit-url'}) }</label>
            </div>
          </div>
          <div className="row">
            <div className="input-field col s12">
              <i className="material-icons prefix">insert_comment</i>
              <input id="image_alt" type="text" className="validate" value={this.state.alt}
                onBlur = {
                  e => {
                      this.changeImageAlt(e)
                  }
                } />
              <label className="active" for="image_alt">{ formatMessage({id: 'image.edit-alt'}) }</label>
            </div>
          </div>
          <div className="row">
          <div className="file-field input-field">
            <div className="btn">
              <span>File</span>
                <input type="file" accept="image/*" onChange={this.onChangeHandler}/>
            </div>
            <div className="file-path-wrapper">
              <input className="file-path validate" type="text" />
            </div>
          </div>
          <div className="input-field col s12">
            <Image
              source={this.state.source}
              alt={this.state.alt}
              url={this.state.url}
            ></Image>
          </div>
          </div>
          <div className="row">
            <div className="col s4">
              <button className="waves-effect waves-light btn"
                onClick={() => {
                  this.save()
                }}>
                { formatMessage({id: 'image.save'}) }
              </button>
            </div>
            <div className="col s4">
              <button className="waves-effect waves-light btn"
                onClick={() => {
                  this.cancel()
                }}>
              { formatMessage({id: 'image.cancel'}) }
              </button>
            </div>
            <div className="col s4"></div>
          </div>
        </form>
        {
          this.state.showUploadImageModal ?
          <ImageUploader
            previousImage={this.state.source}
            callback={url => {
              this.setImageSource(url)
            }} /> : null
        }
      </div>
    );
  }
}

export default injectIntl(ImageDetailEditor);
