import React, { Component } from 'react'
import store from '../../../redux/store'
import ImageDetailEditor from '../../shared/modals/Image/ImageDetailEditor'
import CustomJoditEditor from '../../shared/modals/JoditEditor/Container'
import M from 'materialize-css'
class SidebarEditor extends Component {
  unsubscribe = null
  constructor(props) {
    super(props)
    this.state = {
      modalName: '',
      modalPayload: {},
      sideNavEditorOption:
      {
        edge: 'right', // Choose the horizontal origin
        closeOnClick: true, // Closes side-nav on <a> clicks, useful for Angular/Meteor
        draggable: true, // Choose whether you can drag to open on touch screens,
        onOpen: function (el) { /* Do Stuff*/ }, // A function to be called when sideNav is opened
        onClose: function(el) { /* Do Stuff*/ }, // A function to be called when sideNav is closed
      }
    }
  }

  componentDidMount() {
    const sideNav = document.querySelector('#editor-template-side-nav-editor')
    M.Sidenav.init(sideNav,this.state.sideNavEditorOption);
    this.setState({sideNavEditor: M.Sidenav.getInstance(sideNav)})
    store.subscribe(() => {
      this.setState({
        modalName: store.getState().modals.name,
        modalPayload: store.getState().modals.props,
      })
    })
  }

  closeModal(payload) {
    console.log(payload, 'Close SidebarEditor')
    const { sideNavEditor } = this.state
    const { callBack } = this.state.modalPayload
    this.setState({
      modalName: ''
    }, () => {
      callBack(payload)
      sideNavEditor.close()
    })
  }
  render() {
    let { modalName, sideNavEditor } = this.state
    if(modalName)
      sideNavEditor.open()
    return (
      <ul id="editor-template-side-nav-editor" className="sidenav" >
          {
            (() => {
              switch (modalName) {
                case 'ImageDetailEditor':
                  return (<ImageDetailEditor
                    {...this.state.modalPayload}
                    callback={payload => {
                      this.closeModal(payload)
                    }}
                    cancel={() => this.closeModal(null)}
                  />)
                case 'CustomJoditEditor':
                  return (<CustomJoditEditor
                    {...this.state.modalPayload}
                    callBack={payload => this.closeModal(payload)}
                  />)
              default:
                break;
            }
          })()
        }
      </ul>
    )
  }
}
export default SidebarEditor
