import { endpoint } from '../../constants';
import BaseService from '../../services/BaseService';
import UserService from '../../services/UserService';

const EmailReportService = {
  getGeneralMetrics: payload => {
    const axios = new BaseService({ 'Authorization': UserService.getToken() })
    const complement =
      ['account']
        .map(param => payload[param] ? `${param}=${payload[param]}` : `${param}=`).join('&');
    return axios.get(endpoint(`report/mail?${complement}`));
  },
  getGeneralMetricsByDate: payload => {
    const axios = new BaseService({ 'Authorization': UserService.getToken() })
    const complement =
      ['account', 'from', 'to', 'sentType']
        .map(param => payload[param] ? `${param}=${payload[param]}` : `${param}=`).join('&');
    return axios.get(endpoint(`report/mail?${complement}`));
  },
  getUnsubscribeReport: payload => {
    const axios = new BaseService({ 'Authorization': UserService.getToken() })
    const complement =
      ['date', 'page', 'limit']
        .map(param => payload[param] ? `${param}=${payload[param]}` : `${param}=`).join('&');
    return axios.get(endpoint(`report/mail/unsubscribe?${complement}`));
  },
  getDevicesReport: payload => {
    const axios = new BaseService({ 'Authorization': UserService.getToken() })
    const complement =
      ['account', 'from', 'to', 'page', 'limit']
        .map(param => payload[param] ? `${param}=${payload[param]}` : `${param}=`).join('&');
    return axios.get(endpoint(`report/mail/devices-info?${complement}`));
  },
  getOpenedLinksReport: payload => {
    const axios = new BaseService({ 'Authorization': UserService.getToken() })
    const complement =
      ['account', 'from', 'to', 'page', 'limit']
        .map(param => payload[param] ? `${param}=${payload[param]}` : `${param}=`).join('&');
    return axios.get(endpoint(`report/mail/clicks?${complement}`));
  },
  getEmailDashboardReport: payload => {
    const axios = new BaseService({ 'Authorization': UserService.getToken() })
    const complement =
      ['account', 'search', 'from', 'to', 'sentType', 'page', 'limit']
        .map(param => payload[param] ? `${param}=${payload[param]}` : `${param}=`).join('&');
    return axios.get(endpoint(`report/mail/detail?${complement}`));
  },
  getEmailDetailReport: payload => {
    const axios = new BaseService({ 'Authorization': UserService.getToken() })
    const complement =
      ['account', 'search', 'from', 'to', 'sentType', 'page', 'limit']
        .map(param => payload[param] ? `${param}=${payload[param]}` : `${param}=`).join('&');
    return axios.get(endpoint(`report/mail?consolidated=1&${complement}`));
  },
  generateMailReportFile: payload => {
    const axios = new BaseService({ 'Authorization': UserService.getToken() })
    const complement =
      ['account', 'from', 'to', 'sentType']
        .map(param => payload[param] ? `${param}=${payload[param]}` : `${param}=`).join('&');
    return axios.get(endpoint(`report/mail?consolidated=1&download=1&${complement}`));
  },
  generateEventsReportFile: payload => {
    const axios = new BaseService({ 'Authorization': UserService.getToken() })
    const complement =
      ['account', 'from', 'to', 'sentType']
        .map(param => payload[param] ? `${param}=${payload[param]}` : `${param}=`).join('&');
    return axios.get(endpoint(`report/mail/events?${complement}`));
  },
  generateReportFile: (payload, type = 'detail') => {
    const axios = new BaseService({ 'Authorization': UserService.getToken() })
    const complement =
      ['account', 'search', 'from', 'to', 'sentType']
        .map(param => payload[param] ? `${param}=${payload[param]}` : `${param}=`).join('&');
    return axios.get(endpoint(`report/mail/${type}/file?${complement}`));
  }
}
export default EmailReportService;
