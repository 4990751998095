module.exports = {
  endpoint: complement => `${process.env.REACT_APP_API}/api/v1/${complement}`,
  imageUrl: uri => `${process.env.REACT_APP_IMG_URL}/${uri}`,
  graphicColors: [
    '#FF6384',
    "#F7464A",
    '#36A2EB',
    "#46BFBD",
    '#FFCE56',
    "#FDB45C"
  ],
  validAccounts: [
    'BAZTECA',
    'BAZTECA-COBRANZA',
    'BAZTECA_ENM_MKT',
    'BAZTCAENM-COBRANZA',
    'BAZTECA-ALNOVA',
    'BAZTECA_ENM_ALNOVA',
    'BAZTECA_COBRANZA_DLR',
    'EstrategiaFlash',
    'EstrategiaDLR',
    'BAZTECA_COBRANZA_FLASH',
    'Estrategia_ENM_ELEKTRA',
    'PrestaPrend',
    'AZTECA_SEGUROS',
    'BAZTECA_ENM_CAPTACION',
    'DIRECTO',
    'DIRECTO_TEST'
  ],
  omr: [
    'telcel',
    'movistar',
    'at&t'
  ],
  maskedAccounts: [
    'BAZTECA_ENM_MKT',
    'BAZTCAENM-COBRANZA',
    'BAZTECA_ENM_ALNOVA',
    'Estrategia_ENM_ELEKTRA',
    'BAZTECA_ENM_CAPTACION',
    'PrestaPrend',
    'AZTECA_SEGUROS'
  ],
  reportsFolder: `${process.env.REACT_APP_REPORTS_URL}`,
}
