
import BaseService from '../BaseService';
import { endpoint } from '../../constants';
import UserService from '../UserService';

const MessageService = {

  operator: (payload) => {
    const axios = new BaseService({ 'Authorization': UserService.getToken() })
    const complement =
      ['from', 'to', 'account']
        .map(param => payload[param] ? `${param}=${payload[param]}` : `${param}=`).join('&');
    return axios.get(endpoint(`report/message/operator?${complement}`));
  },
  message: (payload) => {
    const axios = new BaseService({ 'Authorization': UserService.getToken() })
    const complement =
      ['from', 'to', 'cid', 'account']
        .map(param => payload[param] ? `${param}=${payload[param]}` : `${param}=`).join('&');
    return axios.get(endpoint(`report/message?${complement}`));
  },
  detailsFile: (payload) => {
    const axios = new BaseService({ 'Authorization': UserService.getToken() })
    const complement =
      ['cid', 'account', 'from', 'to']
        .map(param => payload[param] ? `${param}=${payload[param]}` : `${param}=`).join('&');
    return axios.get(endpoint(`report/message/details?${complement}`));
  },
  responsesFile: (payload) => {
    const axios = new BaseService({ 'Authorization': UserService.getToken() })
    const complement =
      ['cid', 'account', 'from', 'to']
        .map(param => payload[param] ? `${param}=${payload[param]}` : `${param}=`).join('&');
    return axios.get(endpoint(`report/message/user-responses?${complement}`));
  },
  fileIsReady: (payload) => {
    const axios = new BaseService({ 'Authorization': UserService.getToken() })
    const complement =
      ['fileName']
        .map(param => payload[param] ? `${param}=${payload[param]}` : `${param}=`).join('&');
        return axios.get(endpoint(`report/file?${complement}`));
  }
}
export default MessageService;
