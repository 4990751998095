import React, { PureComponent } from 'react'
import FlagIcon from '../flags/flags'
import Select from 'react-select'
import './internationalization.scss';
import store from '../../redux/store';
export default class InternationalizationSelect extends PureComponent {
    constructor(props) {
        super(props)
        this.state = {

        }
    }
    componentDidMount() {
        const options = [
            {value: 'es',label: <FlagIcon code={'mx'} size={'lg'} Component='div' />},
            {value: 'en',label: <FlagIcon code={'us'} size={'lg'} Component='div' />}
        ]
        this.setState({
            options: options,
          selected: options.find(option => option.value === localStorage.getItem('language')) || options.find(option => option.value === 'es')
        })
    }
    languageChanger = (selected) => {
        this.setState({ selected })
        this.languageChangerOnStore(selected.value)
    }

    languageChangerOnStore = (language) => {
        localStorage.setItem('language',language);
        store.dispatch({
            type:'CHANGE_TO_LANGUAGE',
            language
        })
    }
    render() {
        const customStyles = {
            option: (provided, state) => ({
                ...provided,
                borderBottom: '1px dotted pink',
                display: 'flex',
                height: '50px',
                color: state.isSelected ? 'red' : 'blue',
            }),
            control: () => ({
                // none of react-select's styles are passed to <Control />
                width: 50,
                marginRight: '10px'
            }),
            indicatorsContainer: () => ({
                display: 'none',
            }),
            placeholder: () => ({
                display: 'none',
            }),
            menu: () => ({
                width: 50,
                marginRight: '10px'
            }),
            singleValue: (provided, state) => {
                const opacity = state.isDisabled ? 0.5 : 1;
                const transition = 'opacity 300ms';

                return { ...provided, opacity, transition };
            },
        }
        return (
            <div id='internationalization-container' className="myClassName">
                <Select
                    options={this.state.options}
                    styles={customStyles}
                    value={this.state.selected}
                    onChange={e => this.languageChanger(e)}
                />
            </div>
        )
    }

}
