import React from 'react';
import './chart.scss'
import { injectIntl } from 'react-intl';
import { Doughnut, Bar, Pie } from 'react-chartjs-2';
import { graphicColors } from '../../../constants'

let ChartComponent = (props) => {
  const { formatMessage } = props.intl;
  const { type, labels, data, chartName } = props;
  const datasetKeyProvide = () => { return Math.random(); }
  const getGraphic = (type, datasets) => {
    switch (type) {
      case 'Doughnut':
        return (
          <Doughnut
            data={{
              labels: labels,
              datasets: datasets,
            }}
            width={100}
            options={{ maintainAspectRatio: false, animation: {animateRotate : false}}}
          />
        )
      case 'Pie':
        return (
          <Pie
            data={{
              labels: labels,
              datasets: datasets,
            }}
            width={100}
            options={{ maintainAspectRatio: false }}
          />
        )
      case 'Bar':
        return (
          <Bar
            data={{
              labels: labels,
              datasets: datasets,
            }}
            width={100}
            options={{ maintainAspectRatio: false }}
          />
        )
      default:
        break;
    }
  }
  if (labels.length > 0 && data.length > 0) {
    let defaultDataset = {
      backgroundColor: graphicColors,
    }
    switch (type) {
      case 'Doughnutx2':
        let datasetsDoughnut = [];
        datasetsDoughnut = data.map(array => {
          return {
            ...defaultDataset,
            data: array,
            label: datasetKeyProvide()
          }
        })
        return (
          <div className="col graphic-zone s12 m6">
            <div className="z-depth-1 graphic" >
              <label className="title">
                {chartName}
              </label>
              {getGraphic('Doughnut', datasetsDoughnut)}
            </div>
          </div>
        );
      case 'Pie':
        return (
          <div className="col graphic-zone s12 m6">
            <div className="z-depth-1 graphic" >
              <label className="title">
                {chartName}
              </label>
              {getGraphic('Pie', [{
                ...defaultDataset,
                data: data,
              }])}
            </div>
          </div>
        );
      case 'Bar':
        data.map((elem, i) => elem['backgroundColor'] = graphicColors[i] )
        return (
          <div className="col graphic-zone s12 m6">
            <div className="z-depth-1 graphic" >
              <label className="title">
                {chartName}
              </label>
              {getGraphic('Bar', data)}
            </div>
          </div>
        );
      default:
        return (<b>graphic type undefined</b>)
    }
  } else {
    return (
      <div className="col no-graphic-zone s12 m6">
        <div className=' z-depth-1 no-graphic'>
          <span>
            {chartName}
            <hr></hr>
            {formatMessage({ id: 'generals.noInformation' })}
          </span>
        </div>
      </div>
    )
  }
}
export default injectIntl(ChartComponent);
