import BaseService from './BaseService';
import { endpoint } from '../constants';
import UserService from './UserService';

const RatesService = {
  get: () => {
    const axios = new BaseService({ 'Authorization': UserService.getToken() })
    return axios.get(endpoint('rate'));
  },
  create: (payload) => {
    const axios = new BaseService({ 'Authorization': UserService.getToken() })
    return axios.post(endpoint('rate'), payload);
  },
  update: (id, payload) => {
    const axios = new BaseService({ 'Authorization': UserService.getToken() })
    return axios.put(endpoint(`rate/${id}`), payload);
  }
}
export default RatesService;