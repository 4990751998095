import M from "materialize-css";
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { injectIntl } from 'react-intl';
import CompanyService from '../../../services/CompanyService';
import Cards from '../dashboard/cards';
import './dashboard.scss';
import DeviceInfo from './deviceInfo';
import LinkClicks from './linkClicks';
import Unsubscribe from './unsubscribe';

export default injectIntl(({ intl }) => {
  const { formatMessage } = intl
  const [companyAccounts, setCompanyAccounts] = useState([])
  const [account, setAccount] = useState('')
  const [date, setDate] = useState(moment().format('YYYY-MM-DD'))

  useEffect(() => {
    init()
  }, [])
  const init = async () => {
    let response = await CompanyService.getEmailAccounts()
    let accounts = response.result.map(account => account.user)
    setCompanyAccounts(accounts)
    setMaterialize()
  }
  const setMaterialize = () => {
    setTimeout(() => {
      M.AutoInit();
      let elements = document.querySelectorAll('.datepicker');
      M.Datepicker.init(elements, {
        autoClose: true,
        format: 'yyyy-mm-dd',
        minDate: null,
        maxDate: moment().toDate(),
        setDefaultDate: true,
        defaultDate: moment().toDate(),
        onSelect: async date => {
          date = moment(date).format('YYYY-MM-DD')
          console.log(date, 'one')
          setDate(date)
        }
      })
    }, 200)
  }
  const populateSelectAccounts = () => {
    let optionItems = [<option value='' key='*' defaultValue>Todos</option>]
    companyAccounts.map(account => optionItems.push(<option value={account} key={account}>{account}</option>));
    return (
      <select
        id='companyAccounts'
        onChange={e => {
          setAccount(e.target.value.trim())
        }}>
        {optionItems}
      </select>
    )
  }
  return (
    <div id="email-report">
      <div className="row">
        <div className="input-field col s12 m6 datepicker-container">
          <label>
            {formatMessage({id: 'generals.date' })}
          </label>
          <input
            type="text"
            className="datepicker"
            id="datepicker"
          >
          </input>
        </div>
        <div className="input-field col s12 m6 select-container">
          {populateSelectAccounts()}
          <label>{formatMessage({ id: 'generals.accounts' })}</label>
        </div>
      </div>
      <div className="row">
        <Cards
          account={account}
          from={date}
          to={date} />
      </div>
      <div className="row">
        <div className="col s12 m6 deviceInfo">
          <DeviceInfo account={account} from={date} to={date}/>
        </div>
        <div className="col s12 m6 linkClicks">
          <LinkClicks account={account} from={date} to={date}/>
        </div>
        <div className="col s12 m12 unsubscribe">
          <Unsubscribe date={date}/>
        </div>
      </div>
    </div>
  )
})
