import store from '../../../redux/store';
export function storeFileName(fileName) {
  const pendingFiles = localStorage.getItem('pendingFiles');
  let files = [];

  if (pendingFiles) {
    files = JSON.parse(pendingFiles)

    if (!files.includes(fileName)) {
      files.push(fileName)
    }
  } else {
    files.push(fileName)
  }

  localStorage.setItem('pendingFiles', JSON.stringify(files));
  store.dispatch({
    type:'NEW_FILE',
    file: fileName
  })
}