module.exports = [
  {
    id: '1',
    url: '/home',
    icon: 'home',
    title: 'commons.home',
    roles: ['root', 'internal_administrator', 'client', 'client_administrator', 'reports']
  },
  {
    id: '2',
    icon: 'mode_comment',
    title: 'generals.sms',
    roles: ['root', 'internal_administrator', 'client', 'client_administrator', 'reports'],
    children: [
      {
        id: '2',
        url: '/sms/messages',
        icon: 'message',
        title: 'commons.messages',
        roles: ['root', 'internal_administrator', 'client', 'client_administrator', 'reports'],
      },
      {
        id: '2.1',
        url: '/sms/report/directo',
        icon: 'branding_watermark',
        title: 'commons.reports',
        roles: ['root', 'internal_administrator', 'reports']
      },
      {
        id: '2.2',
        url: '/sms/accounts',
        icon: 'account_circle',
        title: 'commons.accounts',
        roles: ['root', 'internal_administrator']
      },
      {
        id: '2.3',
        url: '/sms/rates',
        icon: 'payment',
        title: 'commons.rates',
        roles: ['root', 'internal_administrator']
      },
      {
        id: '2.4',
        url: '/sms/report/client',
        icon: 'branding_watermark',
        title: 'commons.reports',
        roles: ['client', 'client_administrator']
      // },
      // {
      //   id: '2.5',
      //   url: '/sms/campaigns',
      //   icon: 'speaker_notes',
      //   title: 'commons.campaign',
      //   roles: ['client', 'client_administrator', 'root', 'client_administrator']
      }
    ]
  },
  {
    id: '3',
    icon: 'email',
    title: 'generals.email',
    roles: ['root', 'internal_administrator', 'client', 'client_administrator', 'reports'],
    children: [
      {
        id: '3.1',
        icon: 'dashboard',
        url: '/email/',
        title: 'commons.dashboard',
        roles: ['root', 'internal_administrator', 'client', 'client_administrator', 'reports'],
      },
      {
        id: '3.2',
        icon: 'format_list_bulleted',
        url: '/email/list',
        title: 'commons.emails',
        roles: ['root', 'internal_administrator', 'client', 'client_administrator', 'reports'],
      },
      {
        id: '3.2',
        icon: 'description',
        url: '/email/reports',
        title: 'commons.reports',
        roles: ['root', 'internal_administrator', 'client', 'client_administrator', 'reports'],
      }
    ]
  // },
  // {
  //   id: '4',
  //   icon: 'mms',
  //   title: 'generals.rcs',
  //   roles: ['root', 'internal_administrator', 'client', 'client_administrator', 'reports'],
  //   children: [
  //     {
  //       id: '4.1',
  //       url: '/rcs/messages',
  //       icon: 'message',
  //       title: 'commons.messages',
  //       roles: ['root', 'internal_administrator', 'client', 'client_administrator', 'reports'],
  //     }
  //   ]
  }
]
