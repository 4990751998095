import { SHOW_MODAL, HIDE_MODAL } from '../actionTypes';

const initialState = {
  name: '',
  props: {}
};

export default function (state = initialState, action) {
  switch (action.type) {
    case SHOW_MODAL: {
      console.log(action)
      let modal_name = action.name
      let props = action.props
      return {
        ...state,
        name: modal_name,
        props
      };
    }
    case HIDE_MODAL: {
      console.log(action)
      return {
        ...state,
        name: '',
        payload: {}
      };
    }
    default:
      return state;
  }
}
