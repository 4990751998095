module.exports = {
    'es': [
        'MX',
        'COL',
        'AR'
    ],
    'en': [
        'UK',
        'US'
    ]
};